<template>
  <v-card flat style="border-radius: 12px">
    <v-row>
      <v-col class="d-flex align-center" cols="4">
        <span class="font-weight-bold mx-8" style="font-size: 16px"
          >Notifications</span
        >
      </v-col>
      <v-col class="px-7" cols="8">
        <v-tabs
          v-model="tab"
          background-color="#E8E8E8"
          color="primary"
          grow
          class=""
          hide-slider
          height="40px"
          style="border-radius: 12px"
          active-class="background-color: primary white--text"
        >
          <v-tab
            v-for="(item, i) in items"
            :style="
              i === 0
                ? ' border-top-left-radius: 12px; border-bottom-left-radius: 12px; height: 40px; text-transform: capitalize; letter-spacing: 0.0;'
                : i === items.length - 1
                ? 'border-top-right-radius: 12px; border-bottom-right-radius: 12px; height: 40px; text-transform: capitalize; letter-spacing: 0.0;'
                : 'text-transform: capitalize; letter-spacing: 0.0; height: 40px'
            "
            :key="i"
          >
            <span>{{ item.text }}</span>
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-card-text class="d-flex">
      <v-row>
        <v-col cols="6" offset="6">
          <v-row>
            <v-col cols="5">
              <v-select
                label="Filter"
                single-line
                hide-details
                value="TODAY"
                :items="filterCases"
                filled
                dense
                background-color="#E8E8E8"
                rounded
                style="border-radius: 12px !important"
                @change="filterData"
              />
            </v-col>
            <v-col cols="7">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search Notifications"
                single-line
                hide-details
                filled
                dense
                background-color="#E8E8E8"
                rounded
                style="border-radius: 12px !important"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :search="search"
      :items="loading ? [] : notificationsTable"
      item-key="notificationId"
      class="elevation-0"
      style="border-radius: 12px; cursor: pointer"
      :height="test"
      :items-per-page="itemsPerPage"
      @click:row="rowClick"
    >
      <template v-slot:[`item.fromUserFullname`]="{ item }">
        <span :style="[item.IsRead ? {} : { 'font-weight': '600' }]">
          {{ item.fromUserFullname }}</span
        >
      </template>
      <template v-slot:[`item.NotificationTimestamp`]="{ item }">
        <span :style="[item.IsRead ? {} : { 'font-weight': '600' }]">
          {{
            item.NotificationTimestamp
              | dateformat("timestamp_med", userTimeZone)
          }}</span
        >
      </template>
      <template v-slot:[`item.MessageToSend`]="{ item }">
        <span :style="[item.IsRead ? {} : { 'font-weight': '600' }]">{{
          item.MessageToSend
        }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          v-if="item.NotificationTypeId === 7"
          class="mx-n1"
          @click="rejectAppointment(item)"
          :disabled="item.IsRead"
          icon
          ><v-icon color="red">mdi-close</v-icon></v-btn
        >
        <v-btn
          v-if="item.NotificationTypeId === 7"
          icon
          :disabled="item.IsRead"
          @click="acceptAppointment(item)"
          ><v-icon color="green">mdi-check</v-icon></v-btn
        >
        <v-btn class="mx-3" v-if="item.NotificationTypeId !== 7" disabled icon
          ><v-icon color="red">mdi-null</v-icon></v-btn
        >
      </template>
    </v-data-table>
    <CreateAppointmentDialog
      v-if="appointmentDialog"
      :dialog="appointmentDialog"
      :item="notificationItem"
      @close="appointmentDialog = false"
      @false="appointmentProcessed"
    />
  </v-card>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import CreateAppointmentDialog from "@/views/dialogs/CreateAppointmentDialog.vue";
export default {
  components: { CreateAppointmentDialog },
  props: ["items", "loading", "userTimeZone", "itemsPerPage", "userId", "type"],
  data() {
    return {
      tab: 0,
      search: "",
      appointmentDialog: false,
      notificationItem: null,
      filterCases: [
        { text: "Today", value: "TODAY" },
        { text: "Last 7 days", value: "LAST7" },
        { text: "Last 14 days", value: "LAST14" },
        { text: "Last 30 days", value: "LAST30" },
        { text: "All", value: "ALL" },
      ],
    };
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.authentication.userData,
      notifications: (state) => state.notifications.allNotifications,
      abnormalNotifications: (state) =>
        state.notifications.abnormalMeasurements,
      reminders: (state) => state.notifications.reminders,
      missed: (state) => state.notifications.missMeasurements,
      chatNotifications: (state) => state.notifications.chatNotifications,
      appointmentRequests: (state) => state.notifications.appointmentRequests,
    }),
    ...mapGetters({
      pageHeight: "pageHeight",
      roleName: "authentication/getRole",
    }),
    test() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return window.innerHeight - 500;
        case "lg":
          if (this.type === "dashboard") {
            return window.innerHeight - 400;
          } else return window.innerHeight - 740;
        case "xl":
          if (this.type === "dashboard") {
            return window.innerHeight - 400;
          } else return window.innerHeight - 740;
        default:
          return 0;
      }
    },
    notificationsTable() {
      if (this.$route.name === "Patient Details") {
        return this.notificationsTabs(this.tab)?.filter((n) => {
          return n.FromUserId === parseInt(this.$route.params.id);
        });
      } else return this.notificationsTabs(this.tab);
    },
    headers() {
      return [
        { text: "", value: "NotificationTypeId", sortable: false },
        { text: "Patient Name", value: "fromUserFullname" },
        { text: "Primary Doctor", value: "fromUserFullname" },
        { text: "Facility", value: "fromUserFullname" },
        { text: "Message", value: "MessageToSend" },
        { text: "Time", value: "NotificationTimestamp" },
      ];
    },
  },
  methods: {
    rowClick(item) {
      if (item.NotificationTypeId === 7) {
        if (item.IsRead) {
          return;
        }
        this.notificationItem = item;
        this.appointmentDialog = true;
      } else return;
    },
    appointmentProcessed() {
      this.appointmentDialog = false;
      this.$emit("rerender");
    },
    acceptAppointment(item) {
      this.notificationItem = item;
      this.appointmentDialog = true;
    },
    async filterData(item) {
      this.$emit("filter", item);
    },
    notificationsTabs(id) {
      var tab =
        this.roleName === "DOCTOR"
          ? {
              1: this.notifications,
              2: this.abnormalNotifications,
              3: this.missed,
              4: this.appointmentRequests,
            }
          : {
              1: this.notifications,
              2: this.abnormalNotifications,
              3: this.missed,
              4: this.chatNotifications,
            };
      return tab[id + 1];
    },
  },
};
</script>