<template>
  <v-card flat class="">
    <div class="d-flex align-left mb-4 px-2">
      <span
        class=""
        style="font-family: Catamaran, sans-serif !important; font-size: 14px"
      >
        {{ method === "add" ? $t("add") : $t("edit") }} {{ $t("device") }}
      </span>
    </div>

    <v-row>
      <v-card-text v-if="deviceType === 2">
        <v-row>
          <v-col class="mb-n3" cols="12">
            <Input
              :inputRules="rules"
              :inputValue.sync="device_name"
              :inputLabel="'Device Name *'"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              dense
              hide-details
              filled
              :rules="rules"
              style="border-radius: 12px"
              v-model="spO2_level"
            >
              <template #label>
                <label>
                  {{ $t("oxygen") }} {{ $t("saturation") }} SpO<sub
                    >2</sub
                  ></label
                >
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-text-field
              label="High heart beat (BPM)"
              v-model="heartBeatOxy_maximum"
              outlined
              dense
              :rules="rules"
              hide-details
              filled
              style="border-radius: 12px"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-text-field
              label="Low heart beat (BPM)"
              v-model="heartBeatOxy_minimum"
              outlined
              dense
              :rules="rules"
              hide-details
              filled
              style="border-radius: 12px"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-text-field
              label="High Pi (%)"
              v-model="pi_maximum"
              outlined
              dense
              :rules="rules"
              hide-details
              filled
              style="border-radius: 12px"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-text-field
              label="Low Pi (%)"
              v-model="pi_minimum"
              outlined
              dense
              :rules="rules"
              hide-details
              filled
              style="border-radius: 12px"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="deviceType === 1 || deviceType === 6">
        <v-row>
          <v-col class="mb-n3" cols="12">
            <Input
              :inputRules="rules"
              :inputValue.sync="device_name"
              :inputLabel="'Device Name *'"
            />
          </v-col>
        </v-row>
        <v-row v-if="deviceType === 1">
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-text-field
              v-model="temperature_maximum"
              outlined
              dense
              :rules="rules"
              hide-details
              filled
              style="border-radius: 12px"
            >
              <template #label> High temperature &deg;C </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-text-field
              v-model="temperature_minimum"
              outlined
              dense
              :rules="rules"
              hide-details
              filled
              style="border-radius: 12px"
            >
              <template #label>
                <label>Low temperature &deg;C</label>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-text-field
              label="High blood sugar level (mmol/L)"
              v-model="bloodSugar_maximum"
              outlined
              dense
              :rules="rules"
              hide-details
              filled
              style="border-radius: 12px"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-text-field
              label="Low blood sugar level (mmol/L)"
              v-model="bloodSugar_minimum"
              outlined
              dense
              :rules="rules"
              hide-details
              filled
              style="border-radius: 12px"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="deviceType === 3">
        <v-row>
          <v-col class="mb-n3" cols="12">
            <Input
              :inputRules="rules"
              :inputValue.sync="device_name"
              :inputLabel="'Device Name *'"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-text-field
              v-model="systolic_maximum"
              outlined
              dense
              :rules="rules"
              hide-details
              filled
              style="border-radius: 12px"
            >
              <template #label>
                <label>High {{ $t("systolic") }} (mmHg)</label>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-text-field
              v-model="systolic_minimum"
              outlined
              dense
              :rules="rules"
              hide-details
              filled
              style="border-radius: 12px"
            >
              <template #label>
                <label>Low {{ $t("systolic") }} (mmHg)</label>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-text-field
              v-model="diastolic_maximum"
              outlined
              dense
              hide-details
              :rules="rules"
              filled
              style="border-radius: 12px"
            >
              <template #label>
                <label>High {{ $t("diastolic") }} (mmHg)</label>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-text-field
              label="Low"
              v-model="diastolic_minimum"
              outlined
              dense
              hide-details
              :rules="rules"
              filled
              style="border-radius: 12px"
            >
              <template #label>
                <label>Low {{ $t("diastolic") }} (mmHg)</label>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-text-field
              v-model="heartBeat_maximum"
              outlined
              dense
              hide-details
              :rules="rules"
              filled
              style="border-radius: 12px"
            >
              <template #label>
                <label>High {{ $t("heartBeat") }} (BPM)</label>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-text-field
              v-model="heartBeat_minimum"
              outlined
              dense
              hide-details
              :rules="rules"
              filled
              style="border-radius: 12px"
            >
              <template #label>
                <label>Low {{ $t("heartBeat") }} (BPM)</label>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="deviceType === 7">
        <v-row>
          <v-col class="mb-n3" cols="12">
            <Input
              :inputRules="rules"
              :inputValue.sync="device_name"
              :inputLabel="'Device Name *'"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-text-field
              label="High blood sugar level (mmol/L)"
              v-model="bloodSugar_maximum"
              outlined
              dense
              :rules="rules"
              hide-details
              filled
              style="border-radius: 12px"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-text-field
              label="Low blood sugar level (mmol/L)"
              v-model="bloodSugar_minimum"
              outlined
              dense
              :rules="rules"
              hide-details
              filled
              style="border-radius: 12px"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-text-field
              label="High Cholesterol Level (mmol/L)"
              v-model="cholesterol_maximum"
              outlined
              dense
              :rules="rules"
              hide-details
              filled
              style="border-radius: 12px"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-text-field
              label="Low Cholesterol Level (mmol/L)"
              v-model="cholesterol_minimum"
              outlined
              :rules="rules"
              dense
              hide-details
              filled
              style="border-radius: 12px"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="deviceType === 5">
        <v-row>
          <v-col class="mb-n3" cols="12">
            <Input
              :inputRules="rules"
              :inputValue.sync="device_name"
              :inputLabel="'Device Name *'"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-row>
  </v-card>
</template>

<script>
import Input from "./Input.vue";

export default {
  components: { Input },
  props: [
    "method",
    "deviceName",
    "deviceType",
    "minTemp",
    "maxTemp",
    "bloodSugarLevelMin",
    "bloodSugarLevelMax",
    "heartBeatMin",
    "heartBeatMax",
    "heartBeatOxyMin",
    "heartBeatOxyMax",
    "piMin",
    "piMax",
    "systolicMin",
    "systolicMax",
    "diastolicMin",
    "diastolicMax",
    "cholesterolLevelMin",
    "cholesterolLevelMax",
    "spO2",
  ],
  computed: {
    rules() {
      return [(v) => !!v || "Cannot be blank!"];
    },
    device_name: {
      get() {
        return this.deviceName;
      },
      set(newValue) {
        this.$emit("update:deviceName", newValue);
      },
    },
    temperature_minimum: {
      get() {
        return this.minTemp;
      },
      set(newValue) {
        this.$emit("update:minTemp", newValue);
      },
    },
    temperature_maximum: {
      get() {
        return this.maxTemp;
      },
      set(newValue) {
        this.$emit("update:maxTemp", newValue);
      },
    },
    bloodSugar_minimum: {
      get() {
        return this.bloodSugarLevelMin;
      },
      set(newValue) {
        this.$emit("update:bloodSugarLevelMin", newValue);
      },
    },
    bloodSugar_maximum: {
      get() {
        return this.bloodSugarLevelMax;
      },
      set(newValue) {
        this.$emit("update:bloodSugarLevelMax", newValue);
      },
    },
    heartBeat_minimum: {
      get() {
        return this.heartBeatMin;
      },
      set(newValue) {
        this.$emit("update:heartBeatMin", newValue);
      },
    },
    heartBeat_maximum: {
      get() {
        return this.heartBeatMax;
      },
      set(newValue) {
        this.$emit("update:heartBeatMax", newValue);
      },
    },
    heartBeatOxy_minimum: {
      get() {
        return this.heartBeatOxyMin;
      },
      set(newValue) {
        this.$emit("update:heartBeatOxyMin", newValue);
      },
    },
    heartBeatOxy_maximum: {
      get() {
        return this.heartBeatOxyMax;
      },
      set(newValue) {
        this.$emit("update:heartBeatOxyMax", newValue);
      },
    },
    pi_minimum: {
      get() {
        return this.piMin;
      },
      set(newValue) {
        this.$emit("update:piMin", newValue);
      },
    },
    pi_maximum: {
      get() {
        return this.piMax;
      },
      set(newValue) {
        this.$emit("update:piMax", newValue);
      },
    },
    systolic_minimum: {
      get() {
        return this.systolicMin;
      },
      set(newValue) {
        this.$emit("update:systolicMin", newValue);
      },
    },
    systolic_maximum: {
      get() {
        return this.systolicMax;
      },
      set(newValue) {
        this.$emit("update:systolicMax", newValue);
      },
    },
    diastolic_minimum: {
      get() {
        return this.diastolicMin;
      },
      set(newValue) {
        this.$emit("update:diastolicMin", newValue);
      },
    },
    diastolic_maximum: {
      get() {
        return this.diastolicMax;
      },
      set(newValue) {
        this.$emit("update:diastolicMax", newValue);
      },
    },
    cholesterol_minimum: {
      get() {
        return this.cholesterolLevelMin;
      },
      set(newValue) {
        this.$emit("update:cholesterolLevelMin", newValue);
      },
    },
    cholesterol_maximum: {
      get() {
        return this.cholesterolLevelMax;
      },
      set(newValue) {
        this.$emit("update:cholesterolLevelMax", newValue);
      },
    },
    spO2_level: {
      get() {
        return this.spO2;
      },
      set(newValue) {
        this.$emit("update:spO2", newValue);
      },
    },
  },
};
</script>