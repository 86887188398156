<template>
  <div>
    <div
      v-if="activeTreatmentPlan.length === 0"
      class="ma-3 pa-6"
      style="
        backgroundcolor: transparent;
        border: 3px dashed #575ce5;
        border-radius: 5px;
      "
    >
      <span
        class="card_color--text pa-2"
        depressed
        outlined
        style="
          border: solid #575ce5;
          border-radius: 12px;
          background-color: transparent !important;
        "
        >{{ $t("no") }} {{ "Active" }} {{ $t("treatmentPlan") }}</span
      >
    </div>
    <v-card
      v-for="(item, i) in activeTreatmentPlan"
      :key="i"
      class="mx-4 mt-4 pb-3"
    >
      <div class="mx-4 pt-2">
        <span class="d-flex align-left primary--text" style="font-size: 20px">
          {{ item.tpName }}<v-spacer /><v-icon small color="green"
            >mdi-circle</v-icon
          >
        </span>
        <span class="d-flex align-left grey--text font-weight-medium">
          {{ item.tpDescription }}
        </span>
      </div>
      <div v-for="(device, d) in item.treatmentPlanDevicesList" :key="d">
        <v-card-title>
          <v-card
            flat
            color="primary"
            class="pa-1 d-flex align-center justify-center"
            @click="coggClick = true"
          >
            <img
              height="30px"
              width="30px"
              :src="getDeviceImg(device.deviceType)"
            />
          </v-card>
          <div class="mx-4">
            <span
              class="d-flex align-left mb-n2 font-weight-bold"
              style="font-size: 18px"
            >
              {{ getDeviceName(device.deviceType) }}
            </span>
            <span class="d-flex align-left" style="font-size: 16px">
              Measure
              {{ getDeviceName(device.deviceType) + " daily" }}
            </span>
            <div class="d-flex align-left">
              <v-chip
                class="mr-2"
                v-for="t in device.measurementsCount"
                :key="t"
                small
                >{{
                  timeMap(device, t) | dateformat("time_am_pm", null)
                }}</v-chip
              >
            </div>
          </div>
          <v-spacer />
          <v-btn v-if="!video" icon @click="editDevice(device)"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
        </v-card-title>
        <v-card
          class="ma-3"
          style="border-radius: 12px"
          color="grey lighten-3"
          v-if="edit && selectedDevice === device.deviceType"
          flat
        >
          <div class="pa-3">
            <!-- <v-select
              v-model="device.measurementPeriod"
              outlined
              dense
              :items="periods"
              item-text="text"
              item-value="key"
            ></v-select> -->
            <!-- <v-radio-group
              class="d-flex align-center justify-center"
              row
              v-model="device.measurementsCount"
            >
              <v-radio :value="1" label="Once per day"></v-radio>
              <v-radio :value="2" label="Twice per day"></v-radio>
              <v-radio
                :value="3"
                label="Three times per day
                "
              ></v-radio>
              <v-radio
                :value="4"
                label="Four times per day
                "
              ></v-radio>
            </v-radio-group> -->
            <v-select
              v-model="device.measurementsCount"
              outlined
              dense
              :items="times"
              item-text="text"
              item-value="count"
            ></v-select>
            <v-text-field
              v-if="
                device.measurementsCount === 1 ||
                device.measurementsCount === 2 ||
                device.measurementsCount === 3 ||
                device.measurementsCount === 4
              "
              v-model="device.time1"
              dense
              type="time"
              outlined
              label="Time 1"
            ></v-text-field>
            <v-text-field
              v-if="
                device.measurementsCount === 2 ||
                device.measurementsCount === 3 ||
                device.measurementsCount === 4
              "
              v-model="device.time2"
              dense
              type="time"
              outlined
              label="Time 2"
            ></v-text-field>
            <v-text-field
              v-if="
                device.measurementsCount === 3 || device.measurementsCount === 4
              "
              v-model="device.time3"
              dense
              type="time"
              outlined
              label="Time 3"
            ></v-text-field>
            <v-text-field
              v-if="device.measurementsCount === 4"
              v-model="device.time4"
              dense
              type="time"
              outlined
              label="Time 4"
            ></v-text-field>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="save(device)">Save</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-card>
    <Alert
      :title="snackTitle"
      :status="snackStatus"
      :color="snackColor"
    ></Alert>
  </div>
</template>
<script>
import {
  glucose_meter_whtie,
  cholesterol_meter_White,
  oxymeter_White,
  scale_white,
  thermometer_White,
  blood_presure_white,
} from "@/assets";
import Alert from "@/views/Client/components/Alert.vue";
export default {
  components: {
    Alert,
  },
  props: ["treamentPlans", "video"],
  data: () => ({
    tab: null,
    edit: false,
    radio: "1",
    selectedDevice: 0,
    snackStatus: false,
    snackTitle: "",
    snackColor: "",
    periods: [
      { text: "Daily", key: "D" },
      { text: "Weekly", key: "W" },
      { text: "Monthly", key: "M" },
    ],
    times: [
      { count: 1, text: "One time per day" },
      { count: 2, text: "Two times per day" },
      { count: 3, text: "Three times per day" },
      { count: 4, text: "Four times per day" },
    ],
  }),
  computed: {
    activeTreatmentPlan() {
      return this.treamentPlans.filter((t) => {
        return t.status === 1;
      });
    },
  },
  methods: {
    timeMap(device, i) {
      if (i === 1) {
        return device.time1;
      } else if (i === 2) {
        return device.time2;
      } else if (i === 3) {
        return device.time3;
      } else return device.time4;
    },
    periodText(i) {
      if (i === "D") {
        return "Day";
      } else if (i === "W") {
        return "Week";
      } else return "Month";
    },
    periodText1(i) {
      if (i === "D") {
        return "daily";
      } else if (i === "W") {
        return "weekly";
      } else return "monthly";
    },
    editDevice(device) {
      this.edit = !this.edit;
      if (this.edit) {
        this.selectedDevice = device.deviceType;
      } else {
        this.selectedDevice = 0;
      }
    },
    async save(device) {
      this.snackStatus = false;
      const body = {
        treatmentPlanId: this.activeTreatmentPlan[0].id,
        deviceType: device.deviceType,
        measurementPeriod: device.measurementPeriod,
        measurementsCount: device.measurementsCount,
        time1: device.time1,
        time2: device.time2,
        time3: device.time3,
        time4: device.time4,
      };

      if (device.measurementsCount < 4) {
        body.time4 = null;
      }
      if (device.measurementsCount < 3) {
        body.time4 = null;
        body.time3 = null;
      }
      if (device.measurementsCount < 2) {
        body.time4 = null;
        body.time3 = null;
        body.time2 = null;
      }
      await this.$store.dispatch("treatmentplan/deassignDevice", device.id);
      await this.$store
        .dispatch("treatmentplan/assignDevice", body)
        .then(() => {
          this.snackStatus = true;
          this.snackColor = "teal darken-2";
          this.snackTitle = "Device treatment plan edited successfully!";
        });
    },
    measurementPeriod(p) {
      switch (p) {
        case "D":
          return "daily";
        case "M":
          return "monthly";
        case "W":
          return "weekly";
      }
    },
    getDeviceImg(i) {
      switch (i) {
        case 1:
          return thermometer_White;
        case 2:
          return oxymeter_White;
        case 3:
          return blood_presure_white;
        case 5:
          return scale_white;
        case 6:
          return glucose_meter_whtie;
        case 7:
          return cholesterol_meter_White;
        default:
          return "";
      }
    },
    getDeviceName(i) {
      switch (i) {
        case 1:
          return "Thermometer";
        case 2:
          return "Oximeter";
        case 3:
          return "Blood Pressure Meter";
        case 5:
          return "Scale";
        case 6:
          return "Glucose Meter";
        case 7:
          return "Cholesterol Meter";
        default:
          return "";
      }
    },
  },
};
</script>