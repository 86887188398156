<template>
  <v-dialog v-model="dialog" width="1000" persistent>
    <v-form v-model="valid">
      <v-card>
        <!-- <v-card-title class="d-flex justify-center"
          >{{ $t("manage") + " " + $t("devices") }}
          <v-spacer />
          <v-btn icon @click="$emit('close')"
            ><v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title> -->
        <v-card-title class="secondary py-1" style="height: 40px">
          <span class="dialog-title">{{
            $t("manage") + " " + $t("devices")
          }}</span>
          <v-spacer />
          <v-icon small color="primary" @click="$emit('close')"
            >mdi-window-close</v-icon
          >
        </v-card-title>
        <div class="d-flex">
          <v-col cols="3">
            <v-card flat>
              <div class="mb-3 d-flex align-left px-3">
                <span>Devices</span>
              </div>
              <v-list nav dense>
                <v-list-item
                  link
                  v-for="(item, i) in deviceTypes"
                  :key="i"
                  :disabled="item.lowValue === 5 && item.value"
                  class="mb-6"
                  style="
                    background-color: #e8e8e8;
                    border-radius: 12px;
                    border: 1px solid grey;
                    height: 40px;
                  "
                  @click="deviceClick(item)"
                >
                  <v-card
                    flat
                    color="transparent"
                    class="pa-1 d-flex align-center justify-center"
                  >
                    <img
                      height="30px"
                      width="30px"
                      :src="getDeviceImg(item.lowValue, item.value)"
                    />
                  </v-card>

                  <v-list-item-content>
                    <div class="d-flex align-center justify-center">
                      <span
                        :class="
                          item.value
                            ? 'item-title primary--text'
                            : 'item-title grey--text'
                        "
                        >{{ item.longDescription }}</span
                      ><v-spacer />
                      <v-icon
                        v-if="selectedDevice.deviceType === item.lowValue"
                        color="primary"
                        small
                        >mdi-circle</v-icon
                      >
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="9">
            <v-card flat height="100%">
              <CreateDeviceCard
                :method="method"
                :deviceName.sync="selectedDevice.deviceName"
                :deviceType="selectedDevice.deviceType"
                :minTemp.sync="minTemp"
                :maxTemp.sync="maxTemp"
                :bloodSugarLevelMin.sync="bloodSugarLevelMin"
                :bloodSugarLevelMax.sync="bloodSugarLevelMax"
                :heartBeatMin.sync="heartBeatMin"
                :heartBeatMax.sync="heartBeatMax"
                :heartBeatOxyMin.sync="heartBeatOxyMin"
                :heartBeatOxyMax.sync="heartBeatOxyMax"
                :piMin.sync="piMin"
                :piMax.sync="piMax"
                :systolicMin.sync="systolicMin"
                :systolicMax.sync="systolicMax"
                :diastolicMin.sync="diastolicMin"
                :diastolicMax.sync="diastolicMax"
                :cholesterolLevelMin.sync="cholesterolLevelMin"
                :cholesterolLevelMax.sync="cholesterolLevelMax"
                :spO2.sync="spO2"
              />
              <!-- export this to new component -->
              <div
                v-if="
                  selectedDevice.deviceType !== undefined &&
                  activeTreatmentPlanId !== undefined &&
                  method === 'add'
                "
                class="px-1 py-5"
              >
                <v-select
                  v-model="measurementsCount"
                  :items="times"
                  item-text="text"
                  item-value="count"
                  label="Measurement Period"
                  dense
                  background-color="#E8E8E8"
                  rounded
                  :rules="rules"
                  outlined
                  style="border-radius: 12px !important; height: 40px"
                />
                <div class="d-flex my-6">
                  <v-text-field
                    v-if="
                      measurementsCount === 1 ||
                      measurementsCount === 2 ||
                      measurementsCount === 3 ||
                      measurementsCount === 4
                    "
                    v-model="time1"
                    dense
                    hide-details
                    filled
                    style="border-radius: 12px"
                    type="time"
                    outlined
                    :rules="rules"
                    label="Time 1"
                  ></v-text-field>
                  <v-text-field
                    v-if="
                      measurementsCount === 2 ||
                      measurementsCount === 3 ||
                      measurementsCount === 4
                    "
                    class="ml-3"
                    v-model="time2"
                    dense
                    hide-details
                    filled
                    style="border-radius: 12px"
                    type="time"
                    :rules="rules"
                    outlined
                    label="Time 2"
                  ></v-text-field>
                  <v-text-field
                    v-if="measurementsCount === 3 || measurementsCount === 4"
                    v-model="time3"
                    dense
                    hide-details
                    filled
                    style="border-radius: 12px"
                    class="ml-3"
                    :rules="rules"
                    type="time"
                    outlined
                    label="Time 3"
                  ></v-text-field>
                  <v-text-field
                    v-if="measurementsCount === 4"
                    v-model="time4"
                    dense
                    hide-details
                    filled
                    :rules="rules"
                    style="border-radius: 12px"
                    class="ml-3"
                    type="time"
                    outlined
                    label="Time 4"
                  ></v-text-field>
                </div>
              </div>
              <!-- untile here -->
            </v-card>
          </v-col>
        </div>
        <v-card-actions v-if="!video">
          <v-spacer></v-spacer>
          <div class="my-6">
            <v-btn class="table-create-button" @click="$emit('close')">
              <v-icon class="icon-cancel">mdi-window-close</v-icon>
              <span>{{ $t("cancel") }}</span>
            </v-btn>
            <v-btn
              class="table-create-button mx-4"
              :disabled="!valid"
              @click="manageDevice"
            >
              <v-icon class="icon">mdi-plus</v-icon>
              <span>{{ $t("save") }}</span>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import {
  // glucose_meter_whtie,
  // cholesterol_meter_White,
  // oxymeter_White,
  // scale_white,
  // thermometer_White,
  // blood_presure_white,
  thermometer_primary,
  scale_primary,
  glucose_meter_primary,
  cholesterol_meter_primary,
  blood_pressure_primary,
  oxymeter_primary,
  // blood_presure_black,
  // cholesterol_meter_black,
  // glucose_meter_black,
  // oxymeter_black,
  // scale_black,
  // thermometer_black,
  // thermometer_grey,
  blood_pressure_grey,
  thermometer_grey,
  glucose_meter_grey,
  oxymeter_grey,
  scale_grey,
  cholesterol_meter_grey,
} from "@/assets";
import CreateDeviceCard from "@/views/Client/components/CreateDeviceCard.vue";
export default {
  components: { CreateDeviceCard },
  props: [
    "dialog",
    "trustedHubs",
    "activeTreatmentPlan",
    "currentDevice",
    "video",
  ],
  data() {
    return {
      valid: false,
      deviceTypes: [],
      selectedDevice: {},
      deniedDevices: [],
      method: "",
      minTemp: null,
      maxTemp: null,
      minTempF: null,
      maxTempF: null,
      spO2: null,
      heartBeatMin: null,
      heartBeatMax: null,
      heartBeatOxyMin: null,
      heartBeatOxyMax: null,
      piMin: null,
      piMax: null,
      systolicMin: null,
      systolicMax: null,
      diastolicMin: null,
      diastolicMax: null,
      bloodSugarLevelMin: null,
      bloodSugarLevelMax: null,
      cholesterolLevelMin: null,
      cholesterolLevelMax: null,
      measurementsCount: null,
      time1: null,
      time2: null,
      time3: null,
      time4: null,
      times: [
        { count: 1, text: "One time per day" },
        { count: 2, text: "Two times per day" },
        { count: 3, text: "Three times per day" },
        { count: 4, text: "Four times per day" },
      ],
    };
  },
  computed: {
    ...mapState({
      verifiersFromRepo: (state) => state.verifiers.verifiers,
    }),
    ...mapGetters({ roleName: "authentication/getRole" }),
    data() {
      return this.selectedDevice;
    },
    rules() {
      return [(v) => !!v || "Cannot be blank!"];
    },
    activeTreatmentPlanId() {
      return this.activeTreatmentPlan?.id;
    },
  },
  watch: {
    selectedDevice() {
      this.defaultValues();
    },
  },
  async mounted() {
    await this.getDeviceTypes();
    this.deviceClick(this.currentDevice);
  },

  methods: {
    async manageDevice() {
      this.snackStatus = false;
      const body = {
        hubIdentifier: this.data.hubIdentifier,
        deviceIdentifier: this.data.deviceIdentifier,
        deviceType: this.data.deviceType,
        deviceStatus: this.data.deviceStatus,
        deviceName: this.data.deviceName,
        notificationServiceUDID: this.data.notificationServiceUDID,
        notificationCharacteristicsUDID:
          this.data.notificationCharacteristicsUDID,
        notificationCharacteristicsName:
          this.data.notificationCharacteristicsName,
        readServiceUDID: this.data.readServiceUDID,
        readCharacteristicsUDID: this.data.readCharacteristicsUDID,
        readCharacteristicsName: this.data.readCharacteristicsName,
        writeServiceUDID: this.data.writeServiceUDID,
        writeCharacteristicsUDID: this.data.writeCharacteristicsUDID,
        writeCharacteristicsName: this.data.writeCharacteristicsName,
        status: 1,
        id: this.data.id,
      };
      switch (this.data.deviceType) {
        case 1:
          {
            this.data.deviceDefaultValuesJson = {
              MinTemperature: parseFloat(this.minTemp),
              MaxTemperature: parseFloat(this.maxTemp),
            };
          }
          break;
        case 2:
          {
            this.data.deviceDefaultValuesJson = {
              SpO2: parseFloat(this.spO2),
              HeartBeatMin: parseFloat(this.heartBeatOxyMin),
              HeartBeatMax: parseFloat(this.heartBeatOxyMax),
              PiMin: parseFloat(this.piMin),
              PiMax: parseFloat(this.piMax),
            };
          }
          break;
        case 3:
          {
            this.data.deviceDefaultValuesJson = {
              SystolicMin: parseFloat(this.systolicMin),
              SystolicMax: parseFloat(this.systolicMax),
              DiastolicMin: parseFloat(this.diastolicMin),
              DiastolicMax: parseFloat(this.diastolicMax),
              HeartBeatMin: parseFloat(this.heartBeatMin),
              HeartBeatMax: parseFloat(this.heartBeatMax),
            };
          }
          break;
        case 6:
          {
            this.data.deviceDefaultValuesJson = {
              BloodSugarLevelMin: parseFloat(this.bloodSugarLevelMin),
              BloodSugarLevelMax: parseFloat(this.bloodSugarLevelMax),
            };
          }
          break;
        case 7:
          {
            this.data.deviceDefaultValuesJson = {
              BloodSugarLevelMin: parseFloat(this.bloodSugarLevelMin),
              BloodSugarLevelMax: parseFloat(this.bloodSugarLevelMax),
              CholesterolLevelMin: parseFloat(this.cholesterolLevelMin),
              CholesterolLevelMax: parseFloat(this.cholesterolLevelMax),
            };
          }
          break;
      }
      body.deviceDefaultValuesJson = JSON.stringify(
        this.data.deviceDefaultValuesJson
      );
      if (this.method === "add") {
        body.hubIdentifier = this.trustedHubs[0].hubIdentifier;
        await this.addDevice(body);
      } else {
        await this.editDevice(body);
      }
    },
    async addDevice(body) {
      await this.$store
        .dispatch("medicaldevice/createMedicalDevice", body)
        .then(async (res) => {
          res.deviceDefaultValuesJson = body.deviceDefaultValuesJson;
          await this.editDevice(res);
          await this.save();
        })
        .catch(() => {
          this.snackStatus = true;
          this.snackColor = "deep-orange darken-4";
          this.title = "Device could not add successfully!";
        });
      this.$emit("false");
    },
    async editDevice(body) {
      await this.$store
        .dispatch("medicaldevice/editMedicalDevice", body)
        .then(() => {
          this.$emit("false");
        })
        .catch(() => {
          this.snackStatus = true;
          this.snackColor = "deep-orange darken-4";
          this.title = "Device could not update successfully!";
        });
    },
    async save() {
      this.snackStatus = false;
      const body = {
        treatmentPlanId: this.activeTreatmentPlan.id,
        deviceType: this.selectedDevice.deviceType,
        measurementPeriod: "D",
        measurementsCount: this.measurementsCount,
        time1: this.time1,
        time2: this.time2,
        time3: this.time3,
        time4: this.time4,
      };

      if (this.measurementsCount < 4) {
        body.time4 = null;
      }
      if (this.measurementsCount < 3) {
        body.time4 = null;
        body.time3 = null;
      }
      if (this.measurementsCount < 2) {
        body.time4 = null;
        body.time3 = null;
        body.time2 = null;
      }
      await this.$store.dispatch("treatmentplan/assignDevice", body);
    },
    defaultValues() {
      if (this.method !== "add") {
        switch (this.data.deviceType) {
          case 1:
            {
              this.minTemp = JSON.parse(
                this.data.deviceDefaultValuesJson
              ).MinTemperature;
              this.maxTemp = JSON.parse(
                this.data.deviceDefaultValuesJson
              ).MaxTemperature;
              this.minTempF =
                (JSON.parse(this.data.deviceDefaultValuesJson).MinTemperature *
                  9) /
                  5 +
                32;
              this.maxTempF =
                (JSON.parse(this.data.deviceDefaultValuesJson).MaxTemperature *
                  9) /
                  5 +
                32;
            }
            break;
          case 2:
            {
              this.spO2 = JSON.parse(this.data.deviceDefaultValuesJson).SpO2;
              this.heartBeatOxyMin = JSON.parse(
                this.data.deviceDefaultValuesJson
              ).HeartBeatMin;
              this.heartBeatOxyMax = JSON.parse(
                this.data.deviceDefaultValuesJson
              ).HeartBeatMax;
              this.piMin = JSON.parse(this.data.deviceDefaultValuesJson).PiMin;
              this.piMax = JSON.parse(this.data.deviceDefaultValuesJson).PiMax;
            }
            break;
          case 3:
            {
              this.systolicMin = JSON.parse(
                this.data.deviceDefaultValuesJson
              ).SystolicMin;
              this.systolicMax = JSON.parse(
                this.data.deviceDefaultValuesJson
              ).SystolicMax;
              this.diastolicMin = JSON.parse(
                this.data.deviceDefaultValuesJson
              ).DiastolicMin;
              this.diastolicMax = JSON.parse(
                this.data.deviceDefaultValuesJson
              ).DiastolicMax;
              this.heartBeatMin = JSON.parse(
                this.data.deviceDefaultValuesJson
              ).HeartBeatMin;
              this.heartBeatMax = JSON.parse(
                this.data.deviceDefaultValuesJson
              ).HeartBeatMax;
            }
            break;
          case 6:
            {
              this.bloodSugarLevelMin = JSON.parse(
                this.data.deviceDefaultValuesJson
              ).BloodSugarLevelMin;
              this.bloodSugarLevelMax = JSON.parse(
                this.data.deviceDefaultValuesJson
              ).BloodSugarLevelMax;
            }
            break;
          case 7:
            {
              this.bloodSugarLevelMin = JSON.parse(
                this.data.deviceDefaultValuesJson
              ).BloodSugarLevelMin;
              this.bloodSugarLevelMax = JSON.parse(
                this.data.deviceDefaultValuesJson
              ).BloodSugarLevelMax;
              this.cholesterolLevelMin = JSON.parse(
                this.data.deviceDefaultValuesJson
              ).CholesterolLevelMin;
              this.cholesterolLevelMax = JSON.parse(
                this.data.deviceDefaultValuesJson
              ).CholesterolLevelMax;
            }
            break;
        }
      }
    },
    deviceClick(item) {
      if (item.value) {
        this.selectedDevice =
          this.trustedHubs[0].assignedMedicalDevicesList.find(
            (element) => element.deviceType === item.lowValue
          );
        this.method = "edit";
      } else {
        this.selectedDevice = {};
        this.selectedDevice.deviceType = item.lowValue;
        this.method = "add";
      }
    },
    getDeviceImg(i, val) {
      switch (i) {
        case 1:
          if (val) {
            return thermometer_primary;
          }
          return thermometer_grey;
        case 2:
          if (val) {
            return oxymeter_primary;
          }
          return oxymeter_grey;

        case 3:
          if (val) {
            return blood_pressure_primary;
          }
          return blood_pressure_grey;

        case 5:
          if (val) {
            return scale_primary;
          }
          return scale_grey;

        case 6:
          if (val) {
            return glucose_meter_primary;
          }
          return glucose_meter_grey;

        case 7:
          if (val) {
            return cholesterol_meter_primary;
          }
          return cholesterol_meter_grey;

        default:
          return "";
      }
    },
    async getDeviceTypes() {
      let actualDevices = [];
      this.trustedHubs[0]?.assignedMedicalDevicesList.forEach((dev) => {
        actualDevices.push(dev.deviceType);
      });
      let header = {
        domainValue: "DEVICE_TYPE",
      };
      await this.$store
        .dispatch("verifiers/getVerifiersForDomain", header)
        .then(() => {
          this.deviceTypes = this.verifiersFromRepo.map((device) => ({
            ...device,
            value: actualDevices.includes(device.lowValue),
          }));
        });
    },
  },
};
</script>