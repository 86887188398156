<template>
  <v-row class="fill-height px-5">
    <v-col class="pt-6" cols="12" xl="4" lg="5" md="12" sm="12">
      <v-progress-circular v-if="userLoading" indeterminate color="primary" />
      <PatientDetailsCard
        :noChat="false"
        :video="false"
        @device-edited="deviceEdited"
        v-else
      />
    </v-col>
    <v-col class="py-6" cols="12" xl="8" lg="7" md="12" sm="12">
      <RenderDataForPatient v-if="!userLoading" />
      <div class="my-8"></div>
      <v-progress-circular
        v-if="notificationsLoading"
        indeterminate
        color="primary"
      />
      <NotificationsCard
        v-else
        :items="items"
        :itemsPerPage="5"
        :loading="notificationsLoading"
        :userTimeZone="userTimeZone"
        @filter="filterData"
        @rerender="getMyNotifications"
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import RenderDataForPatient from "../RenderDataForPatient.vue";
import NotificationsCard from "./NotificationsCard.vue";
import PatientDetailsCard from "./PatientDetailsCard.vue";
import {
  abnormalNotification,
  allNotification,
  missed_notification_white,
  messages_notification_white,
  appointments_white,
} from "@/assets";
export default {
  components: {
    RenderDataForPatient,
    NotificationsCard,
    PatientDetailsCard,
  },
  data() {
    return {
      userLoading: true,
      notificationsLoading: true,
    };
  },

  async mounted() {
    await this.getUserById();
    await this.getMyNotifications();
  },
  computed: {
    userTimeZone() {
      return this.detailedUser?.userData.userTimezone;
    },
    ...mapState({
      detailedUser: (state) => state.users.detailedUser,
      notifications: (state) => state.notifications.allNotifications,
      abnormalNotifications: (state) =>
        state.notifications.abnormalMeasurements,
      reminders: (state) => state.notifications.reminders,
      missed: (state) => state.notifications.missMeasurements,
      chatNotifications: (state) => state.notifications.chatNotifications,
      appointmentRequests: (state) => state.notifications.appointmentRequests,
    }),
    ...mapGetters({ roleName: "authentication/getRole" }),
    items() {
      var items =
        this.roleName === "DOCTOR"
          ? [
              {
                text: "All Notifications",
                img: allNotification,
                cardColor: "#6600FF",
                read: this.notifications.filter((a) => {
                  return a.IsRead;
                }).length,
                all: this.notifications.length,
              },
              {
                text: "Abnormal",
                img: abnormalNotification,
                cardColor: "#D30202",
                read: this.abnormalNotifications.filter((a) => {
                  return a.IsRead;
                }).length,
                all: this.abnormalNotifications.length,
              },
              {
                text: "Missed",
                img: missed_notification_white,
                cardColor: "#FF931E",
                read: this.missed.filter((a) => {
                  return a.IsRead;
                }).length,
                all: this.missed.length,
              },
              {
                text: "Appointment Requests",
                img: appointments_white,
                cardColor: "#006837",
                read: this.appointmentRequests.filter((a) => {
                  return a.IsRead;
                }).length,
                all: this.appointmentRequests.length,
              },
            ]
          : [
              {
                text: "All Notifications",
                img: allNotification,
                cardColor: "#6600FF",
                read: this.notifications.filter((a) => {
                  return a.IsRead;
                }).length,
                all: this.notifications.length,
              },
              {
                text: "Abnormal",
                img: abnormalNotification,
                cardColor: "#D30202",
                read: this.abnormalNotifications.filter((a) => {
                  return a.IsRead;
                }).length,
                all: this.abnormalNotifications.length,
              },
              {
                text: "Missed",
                img: missed_notification_white,
                cardColor: "#FF931E",
                read: this.missed.filter((a) => {
                  return a.IsRead;
                }).length,
                all: this.missed.length,
              },
              {
                text: "Messages",
                img: messages_notification_white,
                cardColor: "#3A3A3A",
                read: this.chatNotifications.filter((a) => {
                  return a.IsRead;
                }).length,
                all: this.chatNotifications.length,
              },
            ];
      return items;
    },
  },
  methods: {
    deviceEdited() {
      this.getUserById();
    },
    async getUserById() {
      this.userLoading = true;
      await this.$store
        .dispatch("users/getUserById", this.$route.params.id)
        .then(() => {
          this.userLoading = false;
        });
    },
    async filterData(item) {
      this.notificationsLoading = true;
      await this.$store.dispatch("notifications/ALL_NOTIFICATIONS", item);
      this.notificationsLoading = false;
    },
    async getMyNotifications() {
      this.notificationsLoading = true;
      await this.$store.dispatch("notifications/ALL_NOTIFICATIONS", "TODAY");
      this.notificationsLoading = false;
    },
  },
};
</script>