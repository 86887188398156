<template>
  <v-dialog
    v-model="dialog"
    width="600"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    style="background-color: blue; border-radius: 30px !important"
  >
    <v-card flat>
      <v-card-title class="secondary py-1" style="height: 40px">
        <span class="dialog-title"
          >{{ tab === 1 ? $t("reject") : $t("create") }}
          {{ $t("appointment") }}</span
        >
        <v-spacer />
        <v-icon small color="primary" @click="$emit('close')"
          >mdi-window-close</v-icon
        >
      </v-card-title>
      <v-card-text class="mt-12 px-11">
        <v-tabs
          v-model="tab"
          background-color="#E8E8E8"
          color="primary"
          grow
          class=""
          hide-slider
          height="40px"
          style="border-radius: 12px"
          active-class="background-color: primary white--text"
        >
          <v-tab
            v-for="(item, i) in tabs"
            :style="
              i === 0
                ? ' border-top-left-radius: 12px; border-bottom-left-radius: 12px; height: 40px; text-transform: capitalize; letter-spacing: 0.0; '
                : i === 1
                ? 'border-top-right-radius: 12px; border-bottom-right-radius: 12px; height: 40px; text-transform: capitalize; letter-spacing: 0.0;'
                : 'text-transform: capitalize; letter-spacing: 0.0; height: 40px'
            "
            :key="i"
          >
            <span>{{ item.name }}</span>
          </v-tab>
        </v-tabs>
      </v-card-text>
      <v-form v-if="tab === 1" v-model="valid" class="pa-8">
        <v-textarea
          v-model="rejectMessage"
          label="Reject Message"
          outlined
          dense
          class="mx-3"
          :rules="rules"
          filled
          style="border-radius: 12px"
        ></v-textarea>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red"
            class="white--text"
            :disabled="!valid"
            @click="rejectRequest"
            >{{ $t("reject") }}
          </v-btn>
          <v-progress-circular v-if="loading" color="primary" indeterminate />
        </v-card-actions>
      </v-form>
      <v-form v-if="tab === 0" v-model="valid" class="pa-8">
        <div class="px-3">
          <v-text-field
            v-model="appointmentTitle"
            label="Appointment Title"
            outlined
            :rules="rules"
            dense
            filled
            style="border-radius: 12px"
          ></v-text-field>

          <div class="d-flex">
            <v-text-field
              v-model="appointmentDate"
              label="Appointment Date"
              outlined
              dense
              :rules="rules"
              type="date"
              filled
              style="border-radius: 12px"
            ></v-text-field>
            <v-text-field
              v-model="appointmentTime"
              label="Appointment Time"
              outlined
              class="ml-2"
              :rules="rules"
              type="time"
              dense
              filled
              style="border-radius: 12px"
            ></v-text-field>
            <v-text-field
              v-model="appointmentPlannedDurationInMin"
              label="Appointment Planned Duration In Min"
              outlined
              :rules="rulesForMinutes"
              class="ml-2"
              dense
              hint="Minutes!"
              type="number"
              filled
              style="border-radius: 12px"
            ></v-text-field>
          </div>
          <v-textarea
            v-model="appointmentDescription"
            label="Appointment Description"
            outlined
            :rules="rules"
            dense
            filled
            style="border-radius: 12px"
          ></v-textarea>
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" :disabled="!valid" @click="approveRequest"
            >{{ $t("submit") }}
          </v-btn>
          <v-progress-circular v-if="loading" color="primary" indeterminate />
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      tab: 0,
      tabs: [{ name: "Accept" }, { name: "Reject" }],
      header: "accept",
      appointmentDate: null,
      appointmentTime: null,
      appointmentPlannedDurationInMin: null,
      appointmentTitle: "",
      appointmentDescription: "",
      rejectMessage: "",
      valid: false,
      loading: false,
    };
  },
  computed: {
    rules() {
      return [(v) => !!v || "This field cannot be blank!"];
    },
    rulesForMinutes() {
      if (
        this.appointmentPlannedDurationInMin < 0 ||
        this.appointmentPlannedDurationInMin > 59
      ) {
        return ["Please choose a number between 0-59"];
      } else return [];
    },
    dateTime() {
      return this.appointmentDate + "T" + this.appointmentTime + ":00";
    },
  },
  props: ["dialog", "item"],
  methods: {
    async createAppointment() {
      const body = {
        appointmentRequestId: this.item.appointmentRequestId,
        appointmentDateTime: new Date(this.dateTime).toISOString(),
        appointmentPlannedDurationInMin: parseInt(
          this.appointmentPlannedDurationInMin
        ),
        appointmentTitle: this.appointmentTitle,
        appointmentDescription: this.appointmentDescription,
      };
      await this.$store
        .dispatch("appointments/createAppointment", body)
        .then(async () => {
          await this.markAsRead();
        })
        .catch(() => {});
      this.$emit("false");
      this.loading = false;
    },
    async rejectRequest() {
      this.loading = true;
      const body = {
        appointmentRequestId: this.item.appointmentRequestId,
        approveThis: false,
        responseComment: this.rejectMessage,
      };
      await this.$store
        .dispatch("appointmentrequests/approveRequest", body)
        .then(async () => {
          await this.markAsRead();
        })
        .catch(() => {});
      this.$emit("false");
      this.loading = false;
    },
    async approveRequest() {
      this.loading = true;
      const body = {
        appointmentRequestId: this.item.appointmentRequestId,
        approveThis: true,
        responseComment: "",
      };
      await this.$store
        .dispatch("appointmentrequests/approveRequest", body)
        .then(async () => {
          await this.createAppointment();
        })
        .catch(() => {});
    },
    async markAsRead() {
      await this.$store.dispatch(
        "notifications/markAsRead",
        this.item.NotificationId
      );
    },
  },
};
</script>