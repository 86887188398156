<template>
  <div
    ref="container"
    class="mx-2 my-1 pa-2"
    :style="
      messages.length > 3
        ? 'background-color: white; border-radius: 12px'
        : 'background-color: white; border-radius: 12px; height:95%'
    "
  >
    <div v-for="(item, i) in messages" :key="i">
      <v-row v-if="alignMessage(item)">
        <v-col cols="7">
          <v-card class="mb-2" rounded="xl" flat>
            <div class="px-4">
              <span class="d-flex justify-start mb-n1 font-weight-bold">{{
                item.fromUserFullname
              }}</span>
              <span
                class="d-flex justify-start mb-n1"
                style="font-size: 16px"
                >{{ item.MessageToSend }}</span
              >
              <span class="d-flex justify-start" style="font-size: 12px">{{
                item.NotificationTimestamp
                  | dateformat("timestamp_med", userDetails.userTimezone)
              }}</span>
            </div>
          </v-card>
        </v-col>
        <v-col cols="5"></v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="5"></v-col>
        <v-col cols="7">
          <v-card class="mb-2" rounded="xl" flat>
            <div class="px-4">
              <span class="d-flex justify-end mb-n1 font-weight-bold">Me</span>
              <span class="d-flex justify-end mb-n1" style="font-size: 16px">{{
                item.MessageToSend
              }}</span>
              <span class="d-flex justify-end" style="font-size: 12px"
                ><v-icon class="mx-2" color="grey" small>{{
                  i !== messages.length - 1
                    ? "mdi-check-all"
                    : msgStatusFromState === "sent"
                    ? "mdi-check"
                    : "mdi-check-all"
                }}</v-icon
                >{{
                  item.NotificationTimestamp
                    | dateformat("timestamp_med", userDetails.userTimezone)
                }}</span
              >
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ["userToChat", "msgStatus"],
  data() {
    return {};
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.authentication.userData,
      notificationsWithUser: (state) =>
        state.notifications.notificationsWithUser,
      msgStatusFromState: (state) => state.notifications.msgStatus,
    }),
    messages() {
      let notif = this.notificationsWithUser;
      if (this.userToChat !== null) {
        let a = notif.filter((n) => {
          return (
            n.ToUserId === this.userToChat.id ||
            (n.ToUserId === this.userToChat.id &&
              n.FromUserId === this.userDetails.id) ||
            (n.ToUserId === this.userDetails.id &&
              n.FromUserId === this.userToChat.id)
          );
        });
        return a;
      } else return [];
    },
  },
  watch: {
    notificationsWithUser(val) {
      if (val.length === 0) {
        return;
      }
      if (val[val.length - 1] == Notification) {
        return;
      }
      if (val[val.length - 1].NotificationTypeId !== 1) {
        return;
      }
      if (this.userToChat?.id === val[val.length - 1]?.FromUserId) {
        this.$store.dispatch(
          "notifications/markAsRead",
          val[val.length - 1].NotificationId
        );
      } else {
        return;
      }
    },
  },
  updated() {
    setTimeout(() => {
      this.scrollToBottom();
    }, 100);
  },
  mounted() {
    setTimeout(() => {
      this.scrollToBottom();
    }, 100);
  },
  methods: {
    scrollToBottom() {
      var content = this.$refs.container;
      content.scrollTop = content.scrollHeight;
    },
    alignMessage(item) {
      if (item.FromUserId === this.userDetails.id) {
        return false;
      } else if (item.FromUserId !== this.userDetails.id) {
        if (item.FromUserId === undefined) {
          return false;
        } else {
          return true;
        }
      }
    },
    time_ago(date) {
      if (typeof date !== "object") {
        date = new Date(date);
      }

      var seconds = Math.floor((new Date() - date) / 1000);
      var intervalType;

      var interval = Math.floor(seconds / 31536000);
      if (interval >= 1) {
        intervalType = "year";
      } else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
          intervalType = "month";
        } else {
          interval = Math.floor(seconds / 86400);
          if (interval >= 1) {
            intervalType = "day";
          } else {
            interval = Math.floor(seconds / 3600);
            if (interval >= 1) {
              intervalType = "hour";
            } else {
              interval = Math.floor(seconds / 60);
              if (interval >= 1) {
                intervalType = "minute";
              } else {
                return "now";
              }
            }
          }
        }
      }
      if (interval > 1 || interval === 0) {
        intervalType += "s";
      }
      return interval + " " + intervalType + " ago";
    },
  },
};
</script>
<style>
::-webkit-scrollbar {
  width: 0px !important;
}
</style>