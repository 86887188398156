<template>
  <div>
    <v-card class="mx-8 mt-8" flat>
      <v-form v-model="valid">
        <v-text-field
          v-model="treatmentPlanName"
          outlined
          dense
          :rules="rules"
          label="Treatment Plan Name"
        ></v-text-field>
        <v-text-field
          v-model="treatmentPlanDescription"
          outlined
          :rules="rules"
          dense
          label="Treatment Plan Description"
        ></v-text-field>
        <v-card-actions class="mt-2">
          <v-spacer />
          <v-btn color="primary" :disabled="!valid" @click="createTreatmentPlan"
            >Save</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
    <Alert
      :title="snackTitle"
      :status="snackStatus"
      :color="snackColor"
    ></Alert>
  </div>
</template>
<script>
import Alert from "@/views/Client/components/Alert.vue";
export default {
  components: {
    Alert,
  },
  computed: {
    rules() {
      return [(v) => !!v || "This field can not be empty!"];
    },
  },
  data: () => ({
    tab: null,
    valid: false,
    loading: false,
    treatmentPlanName: "",
    treatmentPlanDescription: "",
    snackStatus: false,
    snackTitle: "",
    snackColor: "",
  }),
  methods: {
    async createTreatmentPlan() {
      this.snackStatus = false;
      const body = {
        patientId: parseInt(this.$route.params.id),
        tpName: this.treatmentPlanName,
        tpDescription: this.treatmentPlanDescription,
      };
      await this.$store
        .dispatch("treatmentplan/createTreatmentPlan", body)
        .then(() => {
          this.snackStatus = true;
          this.snackColor = "teal darken-2";
          this.snackTitle = "Treatment plan created successfully!";
        })
        .catch(() => {
          this.snackStatus = true;
          this.snackTitle = "Could not create treatment plan!";
          this.snackColor = "deep-orange darken-4";
        });
    },
  },
};
</script>