<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card style="overflow-y: auto: border-radius: 0px !important;">
      <v-card-title class="secondary py-1" style="height: 40px">
        <span class="dialog-title">{{ text }} {{ $t("treatmentPlan") }}</span>
        <v-spacer />
        <v-icon small color="primary" @click="$emit('close')"
          >mdi-window-close</v-icon
        >
      </v-card-title>
      <v-progress-circular v-if="loading" indeterminate color="primary" />
      <v-btn
        v-if="!video"
        class="mt-2"
        text
        :style="method === 'create' ? 'background-color: #575ce5' : ''"
        :color="method === 'create' ? 'white' : 'primary'"
        @click="change('create')"
        >Create</v-btn
      >
      <v-btn
        class="mt-2"
        text
        :style="method === 'active' ? 'background-color: green' : ''"
        :color="method === 'active' ? 'white' : 'green'"
        @click="change('active')"
        >Active</v-btn
      >
      <v-btn
        class="mt-2"
        text
        :style="method === 'history' ? 'background-color: orange' : ''"
        :color="method === 'history' ? 'white' : 'orange'"
        @click="change('history')"
        >History</v-btn
      >
      <div class="pb-4" v-if="!loading">
        <CreateTreatmentPlan
          v-if="method === 'create'"
          :treamentPlans="treamentPlansFromRepo"
        />
        <TreatmentPlanHistory
          v-if="method === 'history'"
          :treamentPlans="treamentPlansFromRepo"
          :userTimezone="userTimezone"
          @back="history = false"
        />
        <ActiveTreatmentPlan
          v-if="method === 'active'"
          :treamentPlans="treamentPlansFromRepo"
          :video="video"
        />
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import ActiveTreatmentPlan from "./ActiveTreatmentPlan.vue";
import CreateTreatmentPlan from "./CreateTreatmentPlan.vue";
import TreatmentPlanHistory from "./TreatmentPlanHistory.vue";
import { mapState } from "vuex";
export default {
  components: {
    CreateTreatmentPlan,
    TreatmentPlanHistory,
    ActiveTreatmentPlan,
  },
  props: ["dialog", "userTimezone", "video"],
  data: () => ({
    history: false,
    loading: true,
    method: "create",
    text: "Create",
  }),
  computed: {
    ...mapState({
      treamentPlansFromRepo: (state) => state.treatmentplan.treamentPlans,
    }),
  },
  async mounted() {
    if (this.video) {
      await this.getTreatmentPlansForPatient(this.$route.params.userId).then(
        () => {
          this.loading = false;
        }
      );
      if (this.treamentPlansFromRepo.length > 0) {
        this.method = "active";
        this.text = "Active";
      }
    } else {
      await this.getTreatmentPlansForPatient(this.$route.params.id).then(() => {
        this.loading = false;
      });
      if (this.treamentPlansFromRepo.length > 0) {
        this.method = "active";
        this.text = "Active";
      }
    }
  },
  methods: {
    change(val) {
      this.method = val;
      if (val === "active") {
        this.text = "Active";
      } else if (val === "history") {
        this.text = "History";
      } else this.text = "Create";
    },
    async getTreatmentPlansForPatient(id) {
      await this.$store.dispatch(
        "treatmentplan/getTreatmentPlansForPatient",
        id
      );
    },
  },
};
</script>