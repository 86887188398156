<script>
import { Line } from "vue-chartjs";
import annotationPlugin from "chartjs-plugin-annotation";

export default {
  extends: Line,
  props: [
    "data",
    "labels",
    "changeScaleUnit",
    "changeTempUnit",
    "changeGlucoseUnit",
    "changeCholesterolUnit",
    "values",
  ],
  mounted() {
    this.renderLineChart();
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    renderLineChart() {
      this.addPlugin(annotationPlugin);
      this.renderChart(
        {
          labels: this.labels,
          datasets: this.labels.length === 0 ? [] : this.newData,
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          annotation: {
            annotations: [
              {
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-0",
                value: this.values.max,
                borderColor: this.labels.length === 0 ? "transparent" : "red",
                borderWidth: 2,
              },
              {
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-0",
                value: this.values.min,
                borderColor: this.labels.length === 0 ? "transparent" : "red",
                borderWidth: 2,
              },
              {
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-0",
                value: this.values.ideal,
                borderColor: this.labels.length === 0 ? "transparent" : "red",
                borderWidth: 2,
              },
            ],
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  min:
                    this.values.min < Math.min(...this.data[0].data)
                      ? this.values.min - this.values.min * 0.05 - 10
                      : Math.min(...this.data[0].data) -
                        Math.min(...this.data[0].data) * 0.05,
                  max:
                    this.values.max > Math.max(...this.data[0].data)
                      ? this.values.max + this.values.max * 0.05
                      : Math.max(...this.data[0].data) +
                        Math.max(...this.data[0].data) * 0.05,
                  stepSize: 2,
                },
              },
            ],
          },
          title: {
            display: true,
            text: "Chart Data",
          },
        }
      );
    },
    handleResize() {
      this.renderLineChart();
    },
  },
  computed: {
    newData() {
      return this.data;
    },
  },
  watch: {
    labels(val, oldVal) {
      if (val !== oldVal) {
        this.renderLineChart();
      }
    },
    changeScaleUnit() {
      this.renderLineChart();
    },
    changeTempUnit() {
      this.renderLineChart();
    },
    changeGlucoseUnit() {
      this.renderLineChart();
    },
    changeCholesterolUnit() {
      this.renderLineChart();
    },
    // data: {
    // 	immediate: true,
    // 	deep: true,
    // 	async handler (newVal, old) {
    //     if (old !== undefined) {
    //       this.renderLineChart()
    //     }
    // 	},
    // },
  },
};
</script>