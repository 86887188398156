<template>
  <v-card class="elevation-0 mt-n3" style="border-radius: 12px">
    <v-row>
      <v-col
        class="d-flex align-center justify-center pl-8"
        cols="4"
        xl="4"
        lg="5"
        md="4"
      >
        <v-card
          flat
          color="#E8E8E8"
          class="d-flex align-center justify-center"
          style="border-radius: 12px"
        >
          <v-img
            :src="`${returnimage}`"
            width="150"
            height="150"
            style="border-radius: 12px"
          ></v-img>
        </v-card>
      </v-col>
      <v-col class="px-0" cols="6" xl="6" lg="5" md="6">
        <h5 class="d-flex align-left mb-n6">
          {{ detailedUser.userData.firstName }}
          {{ detailedUser.userData.lastName }}
        </h5>
        <br />
        <span class="d-flex align-left mb-n6" style="font-size: 12px"
          ><span class="font-weight-bold mr-1"
            >{{ calculateAge(detailedUser.userData.birthdate) }}
          </span>
          years old</span
        ><br />
        <span class="d-flex align-left mb-n6" style="font-size: 12px"
          ><span class="font-weight-bold mr-1">{{
            detailedUser.userData.patientMetadata.heightInCm
          }}</span>
          cm. height</span
        >
        <br />
        <span class="d-flex align-left mb-n6" style="font-size: 12px"
          ><span class="font-weight-bold mr-1">{{
            detailedUser.userData.patientMetadata.weightInKg
          }}</span>
          kg. weight</span
        >
        <br />
        <span
          class="d-flex align-left mb-n6 font-weight-bold"
          style="font-size: 12px"
          >{{ detailedUser.userData.email }}</span
        >
        <br />
        <span class="d-flex align-left mb-n6 font-weight-bold">{{
          detailedUser.userData.phone
        }}</span>
        <br />
        <span class="d-flex align-left mb-n6" style="font-size: 12px"
          >MRN:<span class="font-weight-bold ml-1">
            {{
              detailedUser.userData.patientMetadata.medicalRegistrationNumber
            }}</span
          ></span
        >
        <br />
        <span class="d-flex align-left mb-n6" style="font-size: 12px"
          >Primary Doctor:<span class="font-weight-bold ml-1">
            Name Surname</span
          ></span
        >
        <br />
        <span class="d-flex align-left mb-n4" style="font-size: 12px"
          >Location:
          <span class="font-weight-bold ml-1">{{
            detailedUser.userData.address
          }}</span></span
        >
      </v-col>
      <v-col cols="2">
        <div class="d-flex align-center justify-center">
          <v-card
            v-if="!video"
            flat
            color="primary"
            class="pa-2 d-flex align-center justify-center"
            @click="editPatientDetails = true"
          >
            <img height="18px" width="18px" :src="pencilImg" />
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" class="px-8 mb-n10">
        <v-textarea
          style="border-radius: 12px"
          height="80px"
          label="Known Diagnosis"
          :value="detailedUser.userData.patientMetadata.diagnose"
          outlined
          disabled
        />
      </v-col>
      <v-col cols="12" class="px-8 mb-n10">
        <v-textarea
          style="border-radius: 12px"
          height="80px"
          label="Prescription"
          :value="detailedUser.userData.patientMetadata.prescriptions"
          outlined
          disabled
        />
      </v-col>
    </v-row>
    <v-row class="mb-n6">
      <v-col cols="12" xl="6" lg="6" class="px-3">
        <v-card
          v-if="roleName === 'NURSE' || roleName === 'DOCTOR' || video"
          flat
          class="mx-5"
          color="#E8E8E8"
          style="border-radius: 12px !important"
        >
          <v-card-title style="width: 100%">
            <v-row class="d-flex justify-center align-center px-1">
              <img height="20px" width="20px" :src="medical_team_black" />
              <span class="ml-2" style="font-size: 12px"
                >Medical Team Staff</span
              >
              <v-spacer />
              <v-card
                flat
                color="primary"
                class="pa-1 d-flex align-center justify-center"
                @click="medicalTeamMembersDialog = true"
              >
                <img
                  height="16px"
                  width="16px"
                  :src="video ? see_more_white : pencilImg"
                />
              </v-card>
            </v-row>
          </v-card-title>
        </v-card>
        <v-card
          v-if="roleName === 'HOSPITAL_ADMIN'"
          flat
          class="mx-5"
          color="#E8E8E8"
          style="border-radius: 12px !important"
        >
          <v-card-title style="width: 100%">
            <v-row class="d-flex justify-center align-center px-1">
              <img height="20px" width="20px" :src="medical_team_black" />
              <span class="ml-2" style="font-size: 12px"
                >Medical Team Data</span
              >
              <v-spacer />
              <v-card
                flat
                color="primary"
                class="pa-1 d-flex align-center justify-center"
                @click="addMedicalTeam"
              >
                <v-icon
                  v-if="detailedUser.medicalTeamData.length === 0"
                  size="16px"
                  color="white"
                  >mdi-plus</v-icon
                >
                <img v-else height="16px" width="16px" :src="pencilImg" />
              </v-card>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" xl="6" lg="6" class="px-3">
        <v-card
          v-if="roleName === 'NURSE' || roleName === 'DOCTOR' || video"
          flat
          class="mx-5"
          color="#E8E8E8"
          style="border-radius: 12px !important"
        >
          <v-card-title style="width: 100%">
            <v-row class="d-flex justify-center align-center px-1">
              <img height="20px" width="20px" :src="monitoring_plan_black" />
              <span class="ml-2" style="font-size: 12px">Monitoring Plan</span>
              <v-spacer />
              <v-card
                flat
                color="primary"
                class="pa-1 d-flex align-center justify-center"
                @click="treatmentPlanDialog = true"
              >
                <img
                  height="16px"
                  width="16px"
                  :src="video ? see_more_white : pencilImg"
                />
              </v-card>
            </v-row>
          </v-card-title>
        </v-card>
        <v-card
          v-if="roleName === 'HOSPITAL_ADMIN'"
          flat
          class="mx-5"
          color="#E8E8E8"
          style="border-radius: 12px !important"
        >
          <v-card-title style="width: 100%">
            <v-row class="d-flex justify-center align-center px-1">
              <img height="20px" width="20px" :src="medical_team_black" />
              <span class="ml-2" style="font-size: 12px"
                >Medical Team Members</span
              >
              <v-spacer />
              <v-card
                flat
                color="primary"
                class="pa-1 d-flex align-center justify-center"
                @click="manageMedicalTeamMemberDialog = true"
              >
                <img height="16px" width="16px" :src="pencilImg" />
              </v-card>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <!--    <v-row class="mb-n6">
      <v-col cols="12" class="px-3">
        <v-card
          flat
          class="mx-5"
          color="#E8E8E8"
          style="border-radius: 12px !important"
        >
          <v-card-title style="width: 100%">
            <v-row class="d-flex justify-center align-center px-1">
              <img height="20px" width="20px" :src="devices_black" />
              <span class="ml-2" style="font-size: 12px">Devices</span>
              <v-spacer />
              <div>
                <AssignedHubs
                  :roleName="roleName"
                  :trustedHubs="trustedHubs"
                  :user="detailedUser"
                  :coggClick="coggClick"
                  @close="deviceEdited"
                  @exit="coggClick = false"
                ></AssignedHubs>
              </div>
              <v-spacer />
              <v-card
                flat
                color="primary"
                class="pa-1 d-flex align-center justify-center"
                @click="coggClick = true"
              >
                <img height="16px" width="16px" :src="pencilImg" />
              </v-card>
            </v-row>
          </v-card-title>
        </v-card>
        <v-card
          flat
          color="primary"
          class="pa-1 d-flex align-center justify-center"
          @click="deviceCc()"
        >
          <div><img height="16px" width="16px" :src="pencilImg" /></div>
        </v-card>
      </v-col>
    </v-row> -->
    <!-- <AssignedHubs
      :roleName="roleName"
      :trustedHubs="trustedHubs"
      :user="detailedUser"
      :coggClick="coggClick"
      @close="deviceEdited"
      @exit="coggClick = false"
    ></AssignedHubs> -->
    <v-row class="mb-n6">
      <v-col cols="12" class="px-3">
        <v-card
          flat
          class="mx-5"
          color="#E8E8E8"
          style="border-radius: 12px !important"
        >
          <v-card-title style="width: 100%">
            <v-row class="d-flex justify-center align-center px-1">
              <img height="20px" width="20px" :src="devices_black" />
              <span class="ml-2" style="font-size: 12px">Devices</span>
              <v-spacer />
              <v-card
                class=""
                color="transparent"
                elevation="0"
                style="border-radius: 12px"
              >
                <v-row class="d-flex mx-n2">
                  <v-card
                    v-for="(item, i) in deviceTypes"
                    :key="i"
                    flat
                    :color="item.value ? 'primary' : 'grey'"
                    class="pa-1 mx-2 d-flex align-center justify-center"
                    @click="deviceClick(item)"
                  >
                    <img
                      height="22px"
                      width="22px"
                      :src="getImageForDevice(item.lowValue)"
                    />
                  </v-card>
                </v-row>
              </v-card>
              <!-- <v-spacer />
              <v-card
                flat
                color="primary"
                class="pa-1 d-flex align-center justify-center"
                @click="coggClick = true"
              >
                <img height="16px" width="16px" :src="pencilImg" />
              </v-card> -->
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!noChat">
      <v-col cols="12" class="px-8">
        <Chat :userToChat="detailedUser.userData" />
      </v-col>
    </v-row>
    <ManageDevicesDialog
      v-if="newdialog"
      :dialog="newdialog"
      :trustedHubs="trustedHubs"
      :activeTreatmentPlan="activeTreatmentPlan"
      :video="video"
      :currentDevice="currentDevice"
      @close="newdialog = false"
      @false="deviceEdited"
    />
    <TreatmentPlanDialog
      v-if="treatmentPlanDialog"
      :dialog="treatmentPlanDialog"
      :video="video"
      :userTimezone="detailedUser.userData.userTimezone"
      @close="treatmentPlanDialog = false"
    />
    <AssignMedicalTeamMember
      v-if="manageMedicalTeamMemberDialog && roleName === 'HOSPITAL_ADMIN'"
      :dialog="manageMedicalTeamMemberDialog"
      :medicalTeamId="medicalTeamId"
      :team="medicalTeam"
      :existingMembers="existingMembers"
      @membersChanged="membersChanged = true"
      @false="closeAssignMedicatTeamDialg"
    />
    <MedicalTeamMembers
      v-if="medicalTeamMembersDialog"
      :dialog="medicalTeamMembersDialog"
      :medicalTeams="detailedUser.medicalTeamData"
      @false="medicalTeamMembersDialog = false"
    />
    <ManageMedicalTeamDialog
      v-if="manageMedicalTeamDialog"
      :dialog="manageMedicalTeamDialog"
      :deniedHospitals="deniedHospitals"
      :method="medicalTeamMethod"
      :data="medicalTeamData"
      :userId="detailedUser.userData.id"
      @false="
        () => {
          deviceEdited();
          manageMedicalTeamDialog = false;
        }
      "
      @close="manageMedicalTeamDialog = false"
    />
    <PatientDialog
      v-if="editPatientDetails"
      :dialog="editPatientDetails"
      :type="'edit'"
      :patient="detailedUser"
      @false="editPatientDetails = false"
      @edited="deviceEdited"
    ></PatientDialog>
  </v-card>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import {
  pencilImg,
  medical_team_black,
  glucose_meter_whtie,
  cholesterol_meter_White,
  oxymeter_White,
  scale_white,
  thermometer_White,
  blood_presure_white,
  devices_black,
  monitoring_plan_black,
  see_more_white,
} from "@/assets/";
import MedicalTeamMembers from "@/views/dialogs/MedicalTeamMembers/MedicalTeamMembers.vue";
import Chat from "../Chat/Chat.vue";
import TreatmentPlanDialog from "../TreatmentPlan/TreatmentPlanDialog.vue";
// import AssignedHubs from "../AssignedHubs.vue";
import AssignMedicalTeamMember from "@/views/dialogs/AssignMedicalMember.vue";
import ManageMedicalTeamDialog from "@/views/dialogs/ManageMedicalTeamDialog.vue";
import PatientDialog from "@/views/dialogs/PatientDialog.vue";

import ManageDevicesDialog from "@/views/dialogs/ManageDevicesDialog.vue";
export default {
  components: {
    Chat,
    TreatmentPlanDialog,
    MedicalTeamMembers,
    // AssignedHubs,
    AssignMedicalTeamMember,
    ManageMedicalTeamDialog,
    PatientDialog,
    ManageDevicesDialog,
  },
  props: ["noChat", "video"],
  data() {
    return {
      pencilImg: pencilImg,
      see_more_white: see_more_white,
      medical_team_black: medical_team_black,
      devices_black: devices_black,
      monitoring_plan_black: monitoring_plan_black,
      deviceTypes: [],
      treatmentPlanDialog: false,
      medicalTeamMembersDialog: false,
      manageMedicalTeamMemberDialog: false,
      coggClick: false,
      membersChanged: false,
      manageMedicalTeamDialog: false,
      medicalTeamMethod: "",
      medicalTeamData: {},
      editPatientDetails: false,
      newdialog: false,
      currentDevice: {},
    };
  },
  computed: {
    ...mapState({
      verifiersFromRepo: (state) => state.verifiers.verifiers,
      detailedUser: (state) => state.users.detailedUser,
    }),
    activeTreatmentPlan() {
      return this.detailedUser.treatmentPlanData?.find(
        (element) => element.status === 1
      );
    },
    deniedHospitals() {
      let d = [];
      this.detailedUser.medicalTeamData?.forEach((data) =>
        d.push(data.hospitalId)
      );
      return d;
    },
    medicalTeamId() {
      return this.detailedUser.medicalTeamData[0].id;
    },
    medicalTeam() {
      return this.detailedUser.medicalTeamData[0];
    },
    existingMembers() {
      let e = [];
      this.detailedUser.medicalTeamData[0].membersList.forEach((m) => {
        e.push(m.id);
      });
      return e;
    },
    ...mapGetters({ roleName: "authentication/getRole" }),
    profileImage() {
      return this.detailedUser?.userProfileImage;
    },
    trustedHubs() {
      return this.detailedUser.trustedHubsData;
    },
    returnimage() {
      if (this.profileImage !== null) {
        return "data:image/jpeg;base64," + this.profileImage;
      } else
        return "https://icons.veryicon.com/png/o/object/material-design-icons-1/account-outline.png";
    },
  },
  async mounted() {
    await this.getDeviceTypes();
  },
  methods: {
    deviceClick(item) {
      if (item.lowValue === 5 && item.value) {
        return;
      }
      console.log(item);
      this.currentDevice = item;
      this.newdialog = true;
    },
    addMedicalTeam() {
      this.snackStatus = false;
      let a = [];
      a = this.detailedUser.medicalTeamData?.filter((data) => {
        return !this.deniedHospitals.includes(data.hospitalId);
      });
      if (this.deniedHospitals.length === 0) {
        this.medicalTeamMethod = "add";
        this.medicalTeamData = {};
        this.manageMedicalTeamDialog = true;
      } else {
        if (a.length !== 0) {
          this.medicalTeamMethod = "add";
          this.medicalTeamData = {};
          this.manageMedicalTeamDialog = true;
        } else {
          this.medicalTeamMethod = "edit";
          this.medicalTeamData = this.detailedUser.medicalTeamData[0];
          this.manageMedicalTeamDialog = true;
        }
      }
    },
    closeAssignMedicatTeamDialg() {
      if (this.membersChanged) {
        this.deviceEdited();
      } else {
        this.manageMedicalTeamMemberDialog = false;
      }
    },
    deviceEdited() {
      this.$emit("device-edited");
      this.newdialog = false;
    },
    getImageForDevice(deviceType) {
      switch (deviceType) {
        case 1:
          return thermometer_White;
        case 2:
          return oxymeter_White;
        case 3:
          return blood_presure_white;
        case 5:
          return scale_white;
        case 6:
          return glucose_meter_whtie;
        case 7:
          return cholesterol_meter_White;
      }
    },
    calculateAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString.split("T")[0]);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    async getDeviceTypes() {
      let actualDevices = [];
      this.trustedHubs[0]?.assignedMedicalDevicesList.forEach((dev) => {
        actualDevices.push(dev.deviceType);
      });
      let header = {
        domainValue: "DEVICE_TYPE",
      };
      await this.$store
        .dispatch("verifiers/getVerifiersForDomain", header)
        .then(() => {
          this.deviceTypes = this.verifiersFromRepo.map((device) => ({
            ...device,
            value: actualDevices.includes(device.lowValue),
          }));
        });
    },
  },
};
</script>