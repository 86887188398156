<template>
  <v-card height="390px" color="#e8e8e8" flat style="border-radius: 12px">
    <div class="white--text d-flex py-2 px-4" style="background-color: #6600ff">
      <span>{{
        userToChat === null
          ? ""
          : userToChat.firstName + " " + userToChat.lastName
      }}</span
      ><v-spacer />
      <img height="20px" width="20px" :src="messages_notification_white" />
    </div>

    <Inbox
      :userToChat="userToChat"
      :msgStatus="msgStatus"
      style="height: 73%; overflow-y: auto"
    />
    <v-card-actions class="d-flex pb-2" elevation="0">
      <v-card
        flat
        color="primary"
        class="pa-2 mb-7 d-flex align-center justify-center"
      >
        <img height="24px" width="24px" :src="attach_white" />
      </v-card>
      <v-card
        flat
        color="primary"
        class="pa-2 mb-7 mx-2 d-flex align-center justify-center"
      >
        <img height="24px" width="24px" :src="videocall_white" />
      </v-card>
      <v-text-field
        style="border-radius: 12px"
        dense
        v-model="message"
        label="Send Message"
        outlined
        @keyup.enter.native="sendMessage"
      ></v-text-field>
      <v-card
        flat
        color="primary"
        class="pa-2 mb-7 ml-2 d-flex align-center justify-center"
        @click="sendMessage"
      >
        <img height="24px" width="24px" :src="send_button_white" />
      </v-card>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import {
  messages_notification_white,
  videocall_white,
  attach_white,
  send_button_white,
} from "@/assets";
import Inbox from "./Inbox.vue";
export default {
  props: ["userToChat"],
  components: {
    Inbox,
  },
  data() {
    return {
      chatOpened: false,
      valid: false,
      selected: true,
      message: "",
      patient: -1,
      tab: "tab-3",
      user: null,
      userName: "",
      openedMessage: "",
      msgStatus: "initial",
      messages_notification_white: messages_notification_white,
      attach_white: attach_white,
      videocall_white: videocall_white,
      send_button_white: send_button_white,
    };
  },
  computed: {
    ...mapState({
      patientsFromRepo: (state) => state.users.users,
      chatNotifications: (state) => state.notifications.chatNotifications,
      messagesWithUser: (state) => state.notifications.messagesWithUser,
      msgStatusFromState: (state) => state.notifications.msgStatus,
    }),
    patients() {
      return this.patientsFromRepo
        ?.filter((p) => p.roleData.roleName === "PATIENT")
        .map((p) => {
          return {
            ...p,
            icon: "mdi-message-outline",
            avatar: "mdi-account",
          };
        });
    },
  },
  watch: {
    userToChat(item) {
      this.renderChat(item);
    },
    chatOpened(val) {
      if (val === false) {
        this.$emit("chatClosed");
      }
    },
  },
  mounted() {
    this.renderChat(this.userToChat);
  },
  methods: {
    async renderChat(user) {
      if (user !== null) {
        this.patient = user.id;
        await this.getMessagesWithUser(this.patient);
        if (user.value) {
          await this.markAllAsRead(this.patient);
        }
        this.chatOpened = true;
      }
    },
    async sendMessage() {
      if (this.message === "") {
        return;
      }
      const body = {
        userId: this.patient,
        messageToSend: this.message,
        notificationTypeId: 1,
      };
      this.msgStatus = "sent";
      this.$store
        .dispatch("notifications/changeMessageStatus", body)
        .then(() => {
          this.message = "";
        });
      await this.$store.dispatch("notifications/sendMessage", body).then(() => {
        this.msgStatus = "delivered";
      });
    },
    async getMessagesWithUser(id) {
      await this.$store.dispatch("notifications/getMessagesWithUser", id);
    },
    async markAllAsRead(id) {
      await this.$store
        .dispatch("notifications/markAllAsReadWithUser", id)
        .then(async () => {
          await this.$store.dispatch("notifications/ALL_NOTIFICATIONS");
        });
    },
  },
};
</script>
<style scoped>
.v-bottom-sheet.v-dialog.v-bottom-sheet--inset {
  border-radius: 12px;
}

.v-dialog__content {
  justify-content: right !important;
  margin-bottom: 106px !important;
  margin-right: 131px !important;
}
</style>