<template>
  <v-card class="elevation-0 mt-n3" style="border-radius: 12px">
    <div class="fill-height d-flex align-center" v-if="loading">
      <v-progress-linear large indeterminate color="primary" />
    </div>
    <v-container v-if="!loading" ref="content">
      <v-row>
        <v-col cols="4">
          <v-card-title class="font-weight-bold" style="font-size: 16px">
            Vitals
          </v-card-title>
        </v-col>
        <v-col offset="1" cols="4">
          <v-card
            flat
            class="mt-2 px-6 d-flex align-center justify-start"
            color="#E8E8E8"
            style="border-radius: 12px !important"
          >
            <v-card-title style="width: 100%">
              <v-row class="d-flex justify-center align-center pa-0">
                <div class="d-flex align-left" style="width: 50%">
                  <span
                    style="
                      font-size: 12px;
                      display: inline-block;
                      width: 148px;
                      white-space: nowrap;
                      overflow: hidden !important;
                      text-overflow: ellipsis;
                    "
                    >Measurement Percentage</span
                  >
                </div>
                <div class="d-flex justify-end" style="width: 50%">
                  <v-progress-circular
                    :rotate="-90"
                    :size="24"
                    :width="4"
                    color="primary"
                  >
                  </v-progress-circular>
                </div>
              </v-row>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-select
            value="LAST7"
            :items="filterCases"
            item-text="text"
            item-value="value"
            filled
            dense
            background-color="#E8E8E8"
            rounded
            style="border-radius: 12px !important"
            class="mt-2"
            @change="filterData"
          ></v-select>
        </v-col>
      </v-row>
      <RenderDeviceData
        class="mx-n3"
        v-for="(item, i) in assignedMedicalDevices"
        :key="i"
        :item="item"
        :idealWeight="idealWeight"
        :dates="dates"
        @show="showItem(item)"
        @hide="hideItem(item)"
      />
      <div
        v-if="assignedMedicalDevices === undefined"
        class="pa-6"
        style="
          backgroundcolor: transparent;
          border: 3px dashed #575ce5;
          border-radius: 5px;
        "
      >
        <span
          class="card_color--text pa-2"
          depressed
          outlined
          style="
            border: solid #575ce5;
            background-color: transparent !important;
            border-radius: 8px;
          "
          >{{ $t("thisUserHasNoHub") }}</span
        >
      </div>
      <div
        v-if="
          assignedMedicalDevices !== undefined &&
          assignedMedicalDevices.length === 0
        "
        class="pa-6"
        style="
          backgroundcolor: transparent;
          border: 3px dashed #575ce5;
          border-radius: 5px;
        "
      >
        <span
          class="card_color--text pa-2"
          depressed
          outlined
          style="
            border: solid #575ce5;
            background-color: transparent !important;
            border-radius: 8px;
          "
          >{{ $t("thisUserHasNoDevices") }}</span
        >
      </div>
    </v-container>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import RenderDeviceData from "./RenderDeviceData.vue";
// import blood from "@/assets/devices/blood-pressure.svg";
// import glucose from "@/assets/devices/glucose.svg";
// import oximeter from "@/assets/devices/pulse-oximeter.svg";
// import temperature from "@/assets/devices/heat.svg";
// import weight from "@/assets/devices/scales.svg";
// import cholesterol from "@/assets/devices/cholesterol.svg";
export default {
  props: ["dialog"],
  components: {
    RenderDeviceData,
  },
  data() {
    return {
      dates: ["2021-09-01", "2022-01-01"],
      selectedItem: 1,
      loading: true,
      filterCases: [
        { text: "Today", value: "TODAY" },
        { text: "Last 7 days", value: "LAST7" },
        { text: "Last 14 days", value: "LAST14" },
        { text: "Last 30 days", value: "LAST30" },
        { text: "All", value: "ALL" },
      ],
      hide: {
        temp: false,
        oxi: false,
        blood: false,
        glu: false,
        chol: false,
        scale: false,
      },
      items: [{ hidden: true }, { hidden: true }],
      oximeterTooltip: this.$t("oximeterIconTooltip"),
      cholesterolTooltip: this.$t("cholesterolMeterIconTooltip"),
      thermometerTooltip: this.$t("thermometerIconTooltip"),
      scaleTooltip: this.$t("scaleIconTooltip"),
      gluclosemeterTooltip: this.$t("glucoseMeterIconTooltip"),
      bloodsugarmeterTooltip: this.$t("bloodSugarMeterIconTooltip"),
    };
  },
  computed: {
    dateRangeText() {
      return this.dates[0] + " - " + this.dates[1];
    },
    ...mapState({
      bloodPresure: (state) => state.medicaldevice.bloodPresure,
      hubs: (state) => state.nosessionhubs.hubs,
      detailedUser: (state) => state.users.detailedUser,
    }),
    idealWeight() {
      var weight = 0;
      if (this.detailedUser.userData.gender === "M") {
        weight =
          50 +
          0.9 * (this.detailedUser.userData.patientMetadata.heightInCm - 152);
      } else {
        weight =
          45.5 +
          0.9 * (this.detailedUser.userData.patientMetadata.heightInCm - 152);
      }
      return weight;
    },
    identifier() {
      return this.hubs.hubIdentifier;
    },
    assignedMedicalDevices() {
      return this.hubs.assignedMedicalDevicesList?.map((item) => ({
        ...item,
        img: this.getDeviceImg(item.deviceType),
        icon: this.getDeviceIcon(item.deviceType),
        hide: this.getDeviceStatus(item.deviceType),
        toolTip: this.getDeviceTooltip(item.deviceType),
      }));
    },
    deviceName() {
      return this.bloodPresure.deviceName;
    },
  },
  methods: {
    async filterData(item) {
      const data = {
        id: this.detailedUser.userData.id,
        filter: item,
      };
      await this.getHubsData(data);
    },
    handleDateChange() {
      if (this.dates[0] === undefined || this.dates[1] === undefined) {
        return;
      }
      let startDate = new Date(this.dates[0]);
      let endDate = new Date(this.dates[1]);
      if (startDate < endDate) {
        return;
      }
      this.dates = [
        endDate.toISOString().slice(0, 10),
        startDate.toISOString().slice(0, 10),
      ];
    },
    hideItem(item) {
      switch (item.deviceType) {
        case 1:
          this.hide.temp = true;
          break;
        case 2:
          this.hide.oxi = true;
          break;
        case 3:
          this.hide.blood = true;
          break;
        case 5:
          this.hide.scale = true;
          break;
        case 6:
          this.hide.glu = true;
          break;
        case 7:
          this.hide.chol = true;
          break;
      }
    },
    showItem(item) {
      switch (item.deviceType) {
        case 1:
          this.hide.temp = false;
          break;
        case 2:
          this.hide.oxi = false;
          break;
        case 3:
          this.hide.blood = false;
          break;
        case 5:
          this.hide.scale = false;
          break;
        case 6:
          this.hide.glu = false;
          break;
        case 7:
          this.hide.chol = false;
          break;
      }
    },
    getDeviceStatus(i) {
      var t = null;
      switch (i) {
        case 1:
          t = this.hide.temp;
          break;
        case 2:
          t = this.hide.oxi;
          break;
        case 3:
          t = this.hide.blood;
          break;
        case 5:
          t = this.hide.scale;
          break;
        case 6:
          t = this.hide.glu;
          break;
        case 7:
          t = this.hide.chol;
          break;
      }
      return t;
    },
    getDeviceIcon(i) {
      switch (i) {
        case 1:
          return "mdi-thermometer";
        case 2:
          return "mdi-gauge";
        case 3:
          return "mdi-water";
        case 5:
          return "mdi-scale-bathroom";
        default:
          return "mdi-tablet-cellphone";
      }
    },
    getDeviceImg(i) {
      switch (i) {
        // case 1:
        //   return temperature;
        // case 2:
        //   return oximeter;
        // case 3:
        //   return blood;
        // case 5:
        //   return weight;
        // case 6:
        //   return glucose;
        // case 7:
        //   return cholesterol;
        default:
          return "";
      }
    },
    getDeviceTooltip(i) {
      switch (i) {
        case 1:
          return this.thermometerTooltip;
        case 2:
          return this.oximeterTooltip;
        case 3:
          return this.bloodsugarmeterTooltip;
        case 5:
          return this.scaleTooltip;
        case 6:
          return this.gluclosemeterTooltip;
        case 7:
          return this.cholesterolTooltip;
        default:
          return;
      }
    },
    async getHubsData(data) {
      await this.$store.dispatch("nosessionhubs/getHubsData", data);
      this.loading = false;
    },
  },
  async mounted() {
    const data = {
      id: this.detailedUser.userData.id,
      filter: "LAST7",
    };
    await this.getHubsData(data);
  },
};
</script>