var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"border-radius":"12px"},attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"4"}},[_c('span',{staticClass:"font-weight-bold mx-8",staticStyle:{"font-size":"16px"}},[_vm._v("Notifications")])]),_c('v-col',{staticClass:"px-7",attrs:{"cols":"8"}},[_c('v-tabs',{staticStyle:{"border-radius":"12px"},attrs:{"background-color":"#E8E8E8","color":"primary","grow":"","hide-slider":"","height":"40px","active-class":"background-color: primary white--text"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item,i){return _c('v-tab',{key:i,style:(i === 0
              ? ' border-top-left-radius: 12px; border-bottom-left-radius: 12px; height: 40px; text-transform: capitalize; letter-spacing: 0.0;'
              : i === _vm.items.length - 1
              ? 'border-top-right-radius: 12px; border-bottom-right-radius: 12px; height: 40px; text-transform: capitalize; letter-spacing: 0.0;'
              : 'text-transform: capitalize; letter-spacing: 0.0; height: 40px')},[_c('span',[_vm._v(_vm._s(item.text))])])}),1)],1)],1),_c('v-card-text',{staticClass:"d-flex"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","offset":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-select',{staticStyle:{"border-radius":"12px !important"},attrs:{"label":"Filter","single-line":"","hide-details":"","value":"TODAY","items":_vm.filterCases,"filled":"","dense":"","background-color":"#E8E8E8","rounded":""},on:{"change":_vm.filterData}})],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{staticStyle:{"border-radius":"12px !important"},attrs:{"append-icon":"mdi-magnify","label":"Search Notifications","single-line":"","hide-details":"","filled":"","dense":"","background-color":"#E8E8E8","rounded":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"border-radius":"12px","cursor":"pointer"},attrs:{"loading":_vm.loading,"headers":_vm.headers,"search":_vm.search,"items":_vm.loading ? [] : _vm.notificationsTable,"item-key":"notificationId","height":_vm.test,"items-per-page":_vm.itemsPerPage},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.fromUserFullname",fn:function(ref){
              var item = ref.item;
return [_c('span',{style:([item.IsRead ? {} : { 'font-weight': '600' }])},[_vm._v(" "+_vm._s(item.fromUserFullname))])]}},{key:"item.NotificationTimestamp",fn:function(ref){
              var item = ref.item;
return [_c('span',{style:([item.IsRead ? {} : { 'font-weight': '600' }])},[_vm._v(" "+_vm._s(_vm._f("dateformat")(item.NotificationTimestamp,"timestamp_med", _vm.userTimeZone)))])]}},{key:"item.MessageToSend",fn:function(ref){
              var item = ref.item;
return [_c('span',{style:([item.IsRead ? {} : { 'font-weight': '600' }])},[_vm._v(_vm._s(item.MessageToSend))])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [(item.NotificationTypeId === 7)?_c('v-btn',{staticClass:"mx-n1",attrs:{"disabled":item.IsRead,"icon":""},on:{"click":function($event){return _vm.rejectAppointment(item)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1):_vm._e(),(item.NotificationTypeId === 7)?_c('v-btn',{attrs:{"icon":"","disabled":item.IsRead},on:{"click":function($event){return _vm.acceptAppointment(item)}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")])],1):_vm._e(),(item.NotificationTypeId !== 7)?_c('v-btn',{staticClass:"mx-3",attrs:{"disabled":"","icon":""}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-null")])],1):_vm._e()]}}],null,true)}),(_vm.appointmentDialog)?_c('CreateAppointmentDialog',{attrs:{"dialog":_vm.appointmentDialog,"item":_vm.notificationItem},on:{"close":function($event){_vm.appointmentDialog = false},"false":_vm.appointmentProcessed}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }