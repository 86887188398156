<template>
  <v-dialog v-if="dialog" v-model="dialog" width="500" persistent>
    <v-card flat style="overflow: hidden">
      <!-- <div class="d-flex">
        <span
          class="d-flex lighten-2 justify-center mt-4 mx-4"
          style="font-size: 20px"
        >
          {{ $t("medicalTeam") }} {{ $t("members") }}
        </span>
        <v-spacer />

        <v-icon
          class="d-flex align-right justify-center mt-4 mx-4"
          color="primary"
          @click="$emit('false')"
        >
          mdi-window-close
        </v-icon>
      </div> -->
      <v-card-title class="secondary py-1" style="height: 40px">
        <span class="dialog-title"
          >{{ $t("medicalTeam") }} {{ $t("members") }}</span
        >
        <v-spacer />
        <v-icon small color="primary" @click="$emit('false')"
          >mdi-window-close</v-icon
        >
      </v-card-title>

      <v-card
        class="elevation-1 ma-2"
        v-for="(member, i) in medicalTeams[0].membersList"
        :key="i"
      >
        <v-card-text class="d-flex pa-2">
          <v-card
            flat
            color="primary"
            class="pa-1 d-flex align-center justify-center"
            @click="coggClick = true"
          >
            <v-icon v-if="member.roleId === 3" size="24px" color="white"
              >mdi-doctor</v-icon
            >
            <v-icon v-else size="24px" color="white">mdi-mother-nurse</v-icon>
          </v-card>
          <span class="mt-1 mx-2 primary--text" style="font-size: 20px">
            {{ member.firstName }} {{ member.lastName }}
          </span>
          <v-spacer />
          <span class="mt-1 mx-2" style="font-size: 18px">
            {{ member.roleId === 3 ? "Doctor" : "Nurse" }}
          </span>
        </v-card-text>
      </v-card>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  props: ["dialog", "medicalTeams"],
  computed: {
    ...mapState({
      userDetails: (state) => state.authentication.userData,
    }),
  },
  methods: {},
};
</script>