<template>
  <div>
    <div
      v-if="
        treamentPlans.filter((t) => {
          return t.status !== 1;
        }).length === 0
      "
      class="ma-3 pa-6"
      style="
        backgroundcolor: transparent;
        border: 3px dashed #575ce5;
        border-radius: 5px;
      "
    >
      <span
        class="card_color--text pa-2"
        depressed
        outlined
        style="
          border: solid #575ce5;
          border-radius: 12px;
          background-color: transparent !important;
        "
        >{{ $t("no") }} {{ "History" }} {{ $t("treatmentPlan") }}s</span
      >
    </div>
    <v-card
      v-for="(item, i) in treamentPlans.filter((t) => {
        return t.status !== 1;
      })"
      :key="i"
      class="mx-4 mt-4 pb-2"
    >
      <div class="mx-4 pt-2">
        <span class="d-flex align-left primary--text" style="font-size: 20px">
          {{ item.tpName }}<v-spacer /><v-icon small color="red"
            >mdi-circle</v-icon
          >
        </span>
        <span class="d-flex align-left grey--text font-weight-medium">
          {{ item.tpDescription }}
        </span>
        <span class="d-flex align-left green--text font-weight-medium">
          <v-icon class="mr-2" color="green" small>mdi-calendar</v-icon>Started:
          {{ item.started | dateformat("timestamp_med", userTimezone) }}
        </span>
        <span class="d-flex align-left red--text font-weight-medium">
          <v-icon class="mr-2" color="red" small>mdi-calendar</v-icon>Ended:
          {{ item.ended | dateformat("timestamp_med", userTimezone) }}
        </span>
      </div>
      <div v-for="(device, d) in item.treatmentPlanDevicesList" :key="d">
        <v-card-title>
          <v-card
            flat
            color="primary"
            class="pa-1 d-flex align-center justify-center"
            @click="coggClick = true"
          >
            <img
              height="30px"
              width="30px"
              :src="getDeviceImg(device.deviceType)"
            />
          </v-card>
          <div class="mx-4">
            <span
              class="d-flex align-left mb-n2 font-weight-bold"
              style="font-size: 18px"
            >
              {{ getDeviceName(device.deviceType) }}
            </span>
            <span class="d-flex align-left" style="font-size: 16px">
              Measure
              {{ getDeviceName(device.deviceType) + " daily" }}
            </span>
            <div class="d-flex align-left">
              <v-chip
                class="mr-2"
                v-for="t in device.measurementsCount"
                :key="t"
                small
                >{{
                  timeMap(device, t) | dateformat("time_am_pm", null)
                }}</v-chip
              >
            </div>
          </div>
        </v-card-title>
      </div>
    </v-card>
  </div>
</template>
<script>
import {
  glucose_meter_whtie,
  cholesterol_meter_White,
  oxymeter_White,
  scale_white,
  thermometer_White,
  blood_presure_white,
} from "@/assets";
export default {
  props: ["treamentPlans", "userTimezone"],
  data: () => ({
    tab: null,
  }),
  methods: {
    measurementPeriod(p) {
      switch (p) {
        case "D":
          return "daily";
        case "M":
          return "monthly";
        case "W":
          return "weekly";
      }
    },
    timeMap(device, i) {
      if (i === 1) {
        return device.time1;
      } else if (i === 2) {
        return device.time2;
      } else if (i === 3) {
        return device.time3;
      } else return device.time4;
    },
    getDeviceName(i) {
      switch (i) {
        case 1:
          return "Thermometer";
        case 2:
          return "Oximeter";
        case 3:
          return "Blood Pressure Meter";
        case 5:
          return "Scale";
        case 6:
          return "Glucose Meter";
        case 7:
          return "Cholesterol Meter";
        default:
          return "";
      }
    },
    periodText1(i) {
      if (i === "D") {
        return "daily";
      } else if (i === "W") {
        return "weekly";
      } else return "monthly";
    },
    getDeviceImg(i) {
      switch (i) {
        case 1:
          return thermometer_White;
        case 2:
          return oxymeter_White;
        case 3:
          return blood_presure_white;
        case 5:
          return scale_white;
        case 6:
          return glucose_meter_whtie;
        case 7:
          return cholesterol_meter_White;
        default:
          return "";
      }
    },
  },
};
</script>