<template>
  <div data-app>
    <v-container ref="content">
      <v-card
        :color="item.hide ? 'white' : '#E8E8E8'"
        style="border-radius: 12px"
        :class="item.hide ? 'elevation-2 my-n2' : 'elevation-0 my-n2'"
        width="100%"
      >
        <v-card-title
          class="py-1"
          @click="item.hide ? $emit('show') : $emit('hide')"
          style="cursor: pointer"
        >
          <!-- <v-icon class="mx-6" color="#fff">{{ item.icon }}</v-icon> -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-row v-bind="attrs" v-on="on">
                <v-col cols="10" sm="4" md="4" lg="4" xl="4">
                  <div class="d-flex">
                    <span
                      class="d-flex align left"
                      style="font-size: 14px !important"
                      >{{ item.deviceName }}</span
                    >
                    <v-icon v-if="item.hide">mdi-chevron-up</v-icon>
                    <v-icon v-else>mdi-chevron-down</v-icon>
                  </div>
                </v-col>
                <v-col cols="12" sm="5" md="5" lg="5" xl="5">
                  <span style="font-size: 14px !important">{{
                    lastMeasuredData(item.deviceType)
                  }}</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  md="3"
                  lg="3"
                  xl="3"
                  class="d-flex justify-end"
                >
                  <span
                    style="font-size: 14px !important"
                    v-if="!lastMeasuredTime.includes('NaN')"
                    >{{ lastMeasuredTime }}</span
                  >
                </v-col>
              </v-row>
            </template>
            <span v-if="!item.hide">Tap to expand</span>
            <span v-else>Tap to shrink</span>
          </v-tooltip>
        </v-card-title>
        <v-spacer></v-spacer>
        <div v-if="item.hide">
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="12">
                <v-card
                  v-if="
                    item.deviceType === 1 && item.measurementsData.length !== 0
                  "
                  flat
                >
                  <v-card-text>
                    <v-row dense justify="center">
                      <v-col cols="12" lg="6" xs="2" sm="2" md="2">
                        <span style="color: grey; font-size: 18px" class="mb-2"
                          >Min:
                          <span class="primary--text"
                            >{{ minValues.value
                            }}<span
                              v-html="
                                minValues.unit === 'c'
                                  ? `${celsius}`
                                  : `${fahrenheit}`
                              "
                            ></span>
                            - {{ minValues.date }}</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="12" lg="6" xs="2" sm="2" md="2">
                        <span style="color: grey; font-size: 18px" class="mb-2"
                          >Max:
                          <span class="primary--text"
                            >{{ maxValues.value
                            }}<span
                              v-html="
                                minValues.unit === 'c'
                                  ? `${celsius}`
                                  : `${fahrenheit}`
                              "
                            ></span>
                            - {{ maxValues.date }}</span
                          ></span
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card
                  v-if="
                    item.deviceType === 2 && item.measurementsData.length !== 0
                  "
                  flat
                >
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" lg="6" xs="2" sm="2" md="2" class="mb-2">
                        <span style="color: grey; font-size: 18px" class="mb-2"
                          >Min:
                          <span
                            style="
                              font-weight: 500;
                              font-size: 16px;
                              color: #575ce5;
                            "
                            >{{ minValues.secondValue }}SpO2
                            {{ minValues.value }}bpm PI
                            {{ minValues.thirdValue }}% -
                            {{ minValues.date }}</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="12" lg="6" xs="4" sm="4" md="4" class="mb-2">
                        <span style="color: grey; font-size: 18px" class="mb-2"
                          >Max:
                          <span
                            style="
                              font-weight: 500;
                              font-size: 16px;
                              color: #575ce5;
                            "
                            >{{ maxValues.secondValue }}SpO2
                            {{ maxValues.value }}bpm PI
                            {{ maxValues.thirdValue }}% -
                            {{ maxValues.date }}</span
                          ></span
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card
                  v-if="
                    item.deviceType === 3 && item.measurementsData.length !== 0
                  "
                  flat
                >
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" lg="6" xs="12" sm="12" md="12">
                        <span style="color: grey; font-size: 18px" class="mb-2"
                          >Min:
                          <span
                            style="
                              font-weight: 500;
                              font-size: 16px;
                              color: #575ce5;
                            "
                            >{{ minValues.value }}/{{
                              minValues.secondValue
                            }}mmHg {{ minValues.thirdValue }}bpm -
                            {{ minValues.date }}
                          </span></span
                        >
                      </v-col>
                      <v-col cols="12" lg="6" xs="12" sm="12" md="12">
                        <span style="color: grey; font-size: 18px" class="mb-2"
                          >Min:
                          <span
                            style="
                              font-weight: 500;
                              font-size: 16px;
                              color: #575ce5;
                            "
                            >{{ maxValues.value }}/{{
                              maxValues.secondValue
                            }}mmHg {{ maxValues.thirdValue }}bpm -
                            {{ maxValues.date }}</span
                          ></span
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card
                  v-if="
                    item.deviceType === 5 && item.measurementsData.length !== 0
                  "
                  flat
                >
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" lg="6" xs="12" sm="12" md="12">
                        <span style="color: grey; font-size: 18px" class="mb-2"
                          >Min:
                          <span
                            style="
                              font-weight: 500;
                              font-size: 16px;
                              color: #575ce5;
                            "
                            >{{ minValues.value }} {{ minValues.unit }} -
                            {{ minValues.date }}</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="12" lg="6" xs="12" sm="12" md="12">
                        <span style="color: grey; font-size: 18px" class="mb-2"
                          >Max:
                          <span
                            style="
                              font-weight: 500;
                              font-size: 16px;
                              color: #575ce5;
                            "
                            >{{ maxValues.value }} {{ minValues.unit }} -
                            {{ maxValues.date }}</span
                          ></span
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card
                  v-if="
                    item.deviceType === 6 && item.measurementsData.length !== 0
                  "
                  flat
                >
                  <v-card-text>
                    <v-row dense justify="center">
                      <v-row>
                        <v-col cols="12" lg="6" xs="12" sm="12" md="12">
                          <span
                            style="color: grey; font-size: 18px"
                            class="mb-2"
                            >Min:
                            <span
                              style="
                                font-weight: 500;
                                font-size: 16px;
                                color: #575ce5;
                              "
                              >{{ minValues.value }} {{ minValues.unit }} -
                              {{ minValues.date }}</span
                            ></span
                          >
                        </v-col>
                        <v-col cols="12" lg="6" xs="12" sm="12" md="12">
                          <span
                            style="color: grey; font-size: 18px"
                            class="mb-2"
                            >Max:
                            <span
                              style="
                                font-weight: 500;
                                font-size: 16px;
                                color: #575ce5;
                              "
                              >{{ maxValues.value }} {{ minValues.unit }} -
                              {{ maxValues.date }}</span
                            ></span
                          >
                        </v-col>
                      </v-row>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card
                  v-if="
                    item.deviceType === 7 && item.measurementsData.length !== 0
                  "
                  flat
                >
                  <v-card-text>
                    <v-row dense justify="center">
                      <v-row>
                        <v-col cols="12" lg="6" xs="12" sm="12" md="12">
                          <span
                            style="color: grey; font-size: 18px"
                            class="mb-2"
                            >Min:
                            <span
                              style="
                                font-weight: 500;
                                font-size: 16px;
                                color: #575ce5;
                              "
                              >{{ minValues.value }} {{ minValues.unit }} -
                              {{ minValues.date }}</span
                            ></span
                          >
                        </v-col>
                        <v-col cols="12" lg="6" xs="12" sm="12" md="12">
                          <span
                            style="color: grey; font-size: 18px"
                            class="mb-2"
                            >Max:
                            <span
                              style="
                                font-weight: 500;
                                font-size: 16px;
                                color: #575ce5;
                              "
                              >{{ maxValues.value }} {{ minValues.unit }} -
                              {{ maxValues.date }}</span
                            ></span
                          >
                        </v-col>
                      </v-row>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-row>
              <v-col v-if="item.deviceType !== 2">
                <LineChart
                  v-if="item.measurementsData.length !== 0"
                  style="z-index: 0"
                  :data="data"
                  :labels="labels"
                  :changeScaleUnit="changeScaleUnit"
                  :changeTempUnit="changeTempUnit"
                  :changeGlucoseUnit="changeGlucoseUnit"
                  :changeCholesterolUnit="changeCholesterolUnit"
                  :values="defaultValues('Systolic')"
                />
              </v-col>
              <v-col v-if="item.deviceType === 3">
                <LineChart
                  v-if="item.measurementsData.length !== 0"
                  style="z-index: 0"
                  :data="dataForBpm"
                  :labels="labels"
                  :changeScaleUnit="changeScaleUnit"
                  :changeTempUnit="changeTempUnit"
                  :changeGlucoseUnit="changeGlucoseUnit"
                  :changeCholesterolUnit="changeCholesterolUnit"
                  :values="defaultValues('HeartBeat')"
                />
              </v-col>
              <v-col cols="12" v-if="item.deviceType === 2">
                <LineChart
                  v-if="item.measurementsData.length !== 0"
                  style="z-index: 0"
                  :data="dataForSpo2"
                  :labels="labels"
                  :changeScaleUnit="changeScaleUnit"
                  :changeTempUnit="changeTempUnit"
                  :changeGlucoseUnit="changeGlucoseUnit"
                  :changeCholesterolUnit="changeCholesterolUnit"
                  :values="defaultValues('Spo2')"
                />
              </v-col>
              <v-col cols="12" v-if="item.deviceType === 2">
                <LineChart
                  v-if="item.measurementsData.length !== 0"
                  style="z-index: 0"
                  :data="dataForOxiBpm"
                  :labels="labels"
                  :changeScaleUnit="changeScaleUnit"
                  :changeTempUnit="changeTempUnit"
                  :changeGlucoseUnit="changeGlucoseUnit"
                  :changeCholesterolUnit="changeCholesterolUnit"
                  :values="defaultValues('HeartBeat')"
                />
              </v-col>
              <v-col cols="12" v-if="item.deviceType === 2">
                <LineChart
                  v-if="item.measurementsData.length !== 0"
                  style="z-index: 0"
                  :data="dataForPi"
                  :labels="labels"
                  :changeScaleUnit="changeScaleUnit"
                  :changeTempUnit="changeTempUnit"
                  :changeGlucoseUnit="changeGlucoseUnit"
                  :changeCholesterolUnit="changeCholesterolUnit"
                  :values="defaultValues('Pi')"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="item.measurementsData.length !== 0"
              style="margin-top: 15px"
            >
              <v-col cols="12" xl="6" lg="6" md="12" sm="12">
                <v-data-table
                  :headers="measurementsHeaders"
                  :items="measuredData"
                  :items-per-page="5"
                  :item-class="itemRowBackground"
                ></v-data-table>
              </v-col>
              <v-col
                v-if="deviceType !== 5"
                cols="12"
                xl="6"
                lg="6"
                md="12"
                sm="12"
              >
                <MeasurementReferences :item="item" />
              </v-col>
            </v-row>
            <div
              v-if="item.measurementsData.length === 0"
              style="
                backgroundcolor: transparent;
                padding: 12px;
                border: 3px dashed #575ce5;
                border-radius: 5px;
              "
            >
              <span class="card_color--text pa-2" depressed
                >No data have been measured until now.</span
              >
            </div>
          </v-card-text>
        </div>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import LineChart from "@/views/Client/components/LineChart.vue";
import { formattedDateTime } from "@/utils/luxon-formater";
import MeasurementReferences from "@/views/Client/components/MeasurementReferences.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    LineChart,
    MeasurementReferences,
  },
  data() {
    return {
      // dates: [],
      changeTempUnit: "1",
      changeScaleUnit: "1",
      changeGlucoseUnit: "1",
      changeCholesterolUnit: "1",
      fahrenheit: "&deg;F",
      celsius: "&deg;C",
    };
  },
  props: ["item", "dates", "idealWeight"],
  computed: {
    ...mapGetters({
      units: "metrics/getUnits",
    }),
    lastMeasuredTime() {
      return this.time_ago(this.measuredData[0]?.measuredAt);
    },
    measurementsHeaders() {
      var headers = [];
      switch (this.item.deviceType) {
        case 1:
          headers = [
            { text: "Measured At", value: "measuredAtFormatted" },
            { text: "Temperature in Celcius", value: "Temperature_C" },
            { text: "Temperature in Fahrenheit", value: "Temperature_F" },
          ];
          break;
        case 2:
          headers = [
            { text: "Measured At", value: "measuredAtFormatted" },
            { text: "BPM", value: "BPM" },
            { text: "SpO2", value: "Spo2" },
            { text: "Pulse Index", value: "PI" },
          ];
          break;
        case 3:
          headers = [
            { text: "Measured At", value: "measuredAtFormatted" },
            { text: "Systolic", value: "Sys" },
            { text: "Diastolic", value: "Dias" },
            { text: "Pulse", value: "Pul" },
          ];
          break;
        case 5:
          headers = [
            { text: "Measured At", value: "measuredAtFormatted" },
            { text: "Weight (kg)", value: "weight_kg" },
            { text: "Weight (lbs))", value: "weight_lbs" },
          ];
          break;
        case 6:
          headers = [
            { text: "Measured At", value: "measuredAtFormatted" },
            { text: "Glucose (mg/dL)", value: "glucose_mgL" },
            { text: "Glucose (mmol/L)", value: "glucose_mmolL" },
          ];
          break;
        case 7:
          headers = [
            { text: "Measured At", value: "measuredAtFormatted" },
            { text: "Cholesterol (mg/dL)", value: "cholesterol_mgL" },
            { text: "Cholesterol (mmol/L)", value: "cholesterol_mmolL" },
          ];
          break;
      }
      return headers;
    },
    measurements() {
      return this.item.measurementsData;
    },
    deviceType() {
      return this.item.deviceType;
    },
    measuredData() {
      let values = [];
      if (this.measurements.length > 0) {
        this.measurements.forEach((data) => {
          let val = JSON.parse(data.measuredDataJson);
          val.deviceType = data.deviceType;
          val.measuredAt = data.measuredAt;
          val.measuredDate = formattedDateTime(data.measuredAt, "date", null);
          val.measuredAtFormatted = formattedDateTime(
            data.measuredAt,
            "datetime_short",
            null
          );
          val.recordedAt = data.recordedAt;
          values.push(val);
        });
      }
      return values;
    },
    lastNormalBPM() {
      return this.measuredData[0]?.BPM;
    },
    lastNormalSpo2() {
      return this.measuredData[0]?.Spo2;
    },
    lastNormalPI() {
      return this.measuredData[0]?.PI;
    },
    lastNormalTemperature() {
      if (parseInt(this.changeTempUnit) === 1) {
        return this.measuredData[0]?.Temperature_C;
      } else return this.measuredData[0]?.Temperature_F;
    },
    lastNormalTemperature_F() {
      return this.measuredData[0]?.Temperature_F;
    },
    lastNormalWeight() {
      if (parseInt(this.changeScaleUnit) === 1) {
        return this.measuredData[0]?.weight_kg;
      } else return this.measuredData[0]?.weight_lbs;
    },
    lastNormalGlucose() {
      if (parseInt(this.changeGlucoseUnit) === 1) {
        return this.measuredData[0]?.glucose_mgL;
      } else return this.measuredData[0]?.glucose_mmolL;
    },
    lastNormalCholesterol() {
      if (parseInt(this.changeCholesterolUnit) === 1) {
        return this.measuredData[0]?.cholesterol_mgL;
      } else return this.measuredData[0]?.cholesterol_mmolL;
    },
    lastNormalSystolic() {
      return this.measuredData[0]?.Sys;
    },
    lastNormalDiastolic() {
      return this.measuredData[0]?.Dias;
    },
    lastNormalPulse() {
      return this.measuredData[0]?.Pul;
    },
    valuesDiastolic() {
      let values = [];
      this.measuredData.filter((c) => {
        values.push(c.Dias);
      });
      return values.reverse();
    },
    valuesSystolic() {
      let values = [];
      this.measuredData.filter((c) => {
        values.push(c.Sys);
      });
      return values.reverse();
    },
    valuesPulse() {
      let values = [];
      this.measuredData.filter((c) => {
        values.push(c.Pul);
      });
      return values.reverse();
    },
    valuesGlucoseMgL() {
      let values = [];
      this.measuredData.filter((c) => {
        values.push(c.glucose_mgL);
      });
      return values.reverse();
    },
    valuesGlucoseMmolL() {
      let values = [];
      this.measuredData.filter((c) => {
        values.push(c.glucose_mmolL);
      });
      return values.reverse();
    },
    valuesCholesterolMgL() {
      let values = [];
      this.measuredData.filter((c) => {
        values.push(c.cholesterol_mgL);
      });
      return values.reverse();
    },
    valuesCholesterolMmolL() {
      let values = [];
      this.measuredData.filter((c) => {
        values.push(c.cholesterol_mmolL);
      });
      return values.reverse();
    },
    valuesWeightKg() {
      let values = [];
      this.measuredData.filter((c) => {
        values.push(c.weight_kg);
      });
      return values.reverse();
    },
    valuesWeightLbs() {
      let values = [];
      this.measuredData.filter((c) => {
        values.push(c.weight_lbs);
      });
      return values.reverse();
    },
    valuesTemperature_C() {
      let values = [];
      this.measuredData.filter((c) => {
        values.push(c.Temperature_C);
      });
      return values.reverse();
    },
    valuesTemperature_F() {
      let values = [];
      this.measuredData.filter((c) => {
        values.push(c.Temperature_F);
      });
      return values.reverse();
    },
    valuesBPM() {
      let values = [];
      this.measuredData.filter((c) => {
        values.push(c.BPM);
      });
      return values.reverse();
    },
    valuesSpO2() {
      let values = [];
      this.measuredData.filter((c) => {
        values.push(c.Spo2);
      });
      return values.reverse();
    },
    valuesPI() {
      let values = [];
      this.measuredData.filter((c) => {
        values.push(c.PI);
      });
      return values.reverse();
    },
    labels() {
      var labels = [];
      this.measuredData.filter((c) => {
        labels.push(c.measuredAt.split("T")[0]);
      });
      return labels.reverse();
    },
    data() {
      var data = [];
      switch (this.item.deviceType) {
        case 1:
          if (parseInt(this.changeTempUnit) === 1) {
            data = [
              {
                label: "Temperature in Celcius",
                data: this.valuesTemperature_C,
                backgroundColor: "transparent",
                borderColor: "#3949AB",
                pointBackgroundColor: "rgba(171, 71, 188, 1)",
              },
            ];
          } else
            data = [
              {
                label: "Temperature in Fahrenheit",
                data: this.valuesTemperature_F,
                backgroundColor: "transparent",
                borderColor: "#8E24AA",
                pointBackgroundColor: "rgba(171, 71, 188, 1)",
              },
            ];
          break;
        case 3:
          data = [
            {
              label: "Systolic",
              data: this.valuesSystolic,
              backgroundColor: "transparent",
              borderColor: "#3949AB",
              pointBackgroundColor: "rgba(171, 71, 188, 1)",
            },
            {
              label: "Diastolic",
              data: this.valuesDiastolic,
              backgroundColor: "transparent",
              borderColor: "#B388FF",
              pointBackgroundColor: "rgba(171, 71, 188, 1)",
            },
          ];
          break;
        case 5:
          if (parseInt(this.changeScaleUnit) === 1) {
            data = [
              {
                label: "Weight (kg)",
                data: this.valuesWeightKg,
                backgroundColor: "transparent",
                borderColor: "#3949AB",
                pointBackgroundColor: "rgba(171, 71, 188, 1)",
              },
            ];
          } else {
            data = [
              {
                label: "Weight (lbs)",
                data: this.valuesWeightLbs,
                backgroundColor: "transparent",
                borderColor: "#8E24AA",
                pointBackgroundColor: "rgba(171, 71, 188, 1)",
              },
            ];
          }
          break;
        case 6:
          if (parseInt(this.changeGlucoseUnit) === 1) {
            data = [
              {
                label: "Glucose(mg/dL)",
                data: this.valuesGlucoseMgL,
                backgroundColor: "transparent",
                borderColor: "#3949AB",
                pointBackgroundColor: "rgba(171, 71, 188, 1)",
              },
            ];
          } else {
            data = [
              {
                label: "Glucose(mmol/L)",
                data: this.valuesGlucoseMmolL,
                backgroundColor: "transparent",
                borderColor: "#8E24AA",
                pointBackgroundColor: "rgba(171, 71, 188, 1)",
              },
            ];
          }
          break;
        case 7:
          if (parseInt(this.changeCholesterolUnit) === 1) {
            data = [
              {
                label: "Cholesterol(mg/dL)",
                data: this.valuesCholesterolMgL,
                backgroundColor: "transparent",
                borderColor: "#3949AB",
                pointBackgroundColor: "rgba(171, 71, 188, 1)",
              },
            ];
          } else {
            data = [
              {
                label: "Cholesterol(mmol/L)",
                data: this.valuesCholesterolMmolL,
                backgroundColor: "transparent",
                borderColor: "#8E24AA",
                pointBackgroundColor: "rgba(171, 71, 188, 1)",
              },
            ];
          }
          break;
      }
      return data;
    },
    dataForBpm() {
      var data = [];
      data = [
        {
          label: "Pulse",
          data: this.valuesPulse,
          backgroundColor: "transparent",
          borderColor: "#8E24AA",
          pointBackgroundColor: "rgba(171, 71, 188, 1)",
        },
      ];
      return data;
    },
    dataForSpo2() {
      var data = [];
      data = [
        {
          label: "SpO2",
          data: this.valuesSpO2,
          backgroundColor: "transparent",
          borderColor: "#B388FF",
          pointBackgroundColor: "rgba(171, 71, 188, 1)",
        },
      ];
      return data;
    },
    dataForOxiBpm() {
      var data = [];
      data = [
        {
          label: "BPM",
          data: this.valuesBPM,
          backgroundColor: "transparent",
          borderColor: "#3949AB",
          pointBackgroundColor: "rgba(171, 71, 188, 1)",
        },
      ];
      return data;
    },
    dataForPi() {
      var data = [];
      data = [
        {
          label: "PI",
          data: this.valuesPI,
          backgroundColor: "transparent",
          borderColor: "#8E24AA",
          pointBackgroundColor: "rgba(171, 71, 188, 1)",
        },
      ];
      return data;
    },
    minValues() {
      let temp_array = [];
      this.measuredData.filter((c) => {
        switch (c.device_type) {
          case "1":
            temp_array.push({
              value:
                this.changeTempUnit === "1" ? c.Temperature_C : c.Temperature_F,
              dev: c.device_type,
              date: c.measuredAtFormatted,
              unit: this.changeTempUnit === "1" ? "c" : "f",
            });
            break;
          case "2":
            temp_array.push({
              value: c.BPM,
              dev: c.device_type,
              date: c.measuredAtFormatted,
              secondValue: c.Spo2,
              thirdValue: c.PI,
            });
            break;
          case "3":
            temp_array.push({
              value: c.Dias,
              dev: c.device_type,
              date: c.measuredAtFormatted,
              secondValue: c.Sys,
              thirdValue: c.Pul,
            });
            break;
          case "5":
            temp_array.push({
              value: this.changeScaleUnit === "1" ? c.weight_kg : c.weight_lbs,
              dev: c.device_type,
              date: c.measuredAtFormatted,
              unit: this.changeScaleUnit === "1" ? "kg" : "lbs",
            });
            break;
          case "6":
            temp_array.push({
              value:
                this.changeGlucoseUnit === "1"
                  ? c.glucose_mgL
                  : c.glucose_mmolL,
              dev: c.device_type,
              date: c.measuredAtFormatted,
              unit: this.changeGlucoseUnit === "1" ? "mg/dL" : "mmol/L",
            });
            break;
          case "7":
            temp_array.push({
              value:
                this.changeCholesterolUnit === "1"
                  ? c.cholesterol_mgL
                  : c.cholesterol_mmolL,
              dev: c.device_type,
              date: c.measuredAtFormatted,
              unit: this.changeCholesterolUnit === "1" ? "mg/dL" : "mmol/L",
            });
            break;
        }
      });
      if (this.deviceType === 3) {
        var min_found = Math.min.apply(
          Math,
          temp_array.map(function (o) {
            return o.value;
          })
        );
        var min_found_second_value = Math.min.apply(
          Math,
          temp_array.map(function (o) {
            return o.secondValue;
          })
        );
        var min_found_third_value = Math.min.apply(
          Math,
          temp_array.map(function (o) {
            return o.thirdValue;
          })
        );
        var second_value_date = this.measuredData.filter((c) => {
          return c.Sys === min_found_second_value;
        });
        var third_value_date = this.measuredData.filter((c) => {
          return c.Pul === min_found_third_value;
        });
        var returned_object = temp_array.filter((c) => c.value === min_found);
        returned_object[0].secondValue = min_found_second_value;
        returned_object[0].thirdValue = min_found_third_value;
        returned_object[0].secondValueDate =
          second_value_date[0].measuredAtFormatted;
        returned_object[0].thirdValueDate =
          third_value_date[0].measuredAtFormatted;
        return returned_object[0];
      } else if (this.deviceType === 2) {
        min_found = Math.min.apply(
          Math,
          temp_array.map(function (o) {
            return o.value;
          })
        );
        min_found_second_value = Math.min.apply(
          Math,
          temp_array.map(function (o) {
            return o.secondValue;
          })
        );
        min_found_third_value = Math.min.apply(
          Math,
          temp_array.map(function (o) {
            return o.thirdValue;
          })
        );
        second_value_date = this.measuredData.filter((c) => {
          return c.Spo2 === min_found_second_value;
        });
        third_value_date = this.measuredData.filter((c) => {
          return c.PI === min_found_third_value;
        });
        returned_object = temp_array.filter((c) => c.value === min_found);
        returned_object[0].secondValue = min_found_second_value;
        returned_object[0].thirdValue = min_found_third_value;
        returned_object[0].secondValueDate =
          second_value_date[0].measuredAtFormatted;
        returned_object[0].thirdValueDate =
          third_value_date[0].measuredAtFormatted;
        return returned_object[0];
      } else {
        min_found = Math.min.apply(
          Math,
          temp_array.map(function (o) {
            return o.value;
          })
        );
        returned_object = temp_array.filter((c) => c.value === min_found);
      }
      return returned_object[0];
    },
    maxValues() {
      let temp_array = [];
      this.measuredData.filter((c) => {
        switch (c.device_type) {
          case "1":
            temp_array.push({
              value:
                this.changeTempUnit === "1" ? c.Temperature_C : c.Temperature_F,
              dev: c.device_type,
              date: c.measuredAtFormatted,
              unit: this.changeTempUnit === "1" ? "c" : "f",
            });
            break;
          case "2":
            temp_array.push({
              value: c.BPM,
              dev: c.device_type,
              date: c.measuredAtFormatted,
              secondValue: c.Spo2,
              thirdValue: c.PI,
            });
            break;
          case "3":
            temp_array.push({
              value: c.Dias,
              dev: c.device_type,
              date: c.measuredAtFormatted,
              secondValue: c.Sys,
              thirdValue: c.Pul,
            });
            break;
          case "5":
            temp_array.push({
              value: this.changeScaleUnit === "1" ? c.weight_kg : c.weight_lbs,
              dev: c.device_type,
              date: c.measuredAtFormatted,
              unit: this.changeScaleUnit === "1" ? "kg" : "lbs",
            });
            break;
          case "6":
            temp_array.push({
              value:
                this.changeGlucoseUnit === "1"
                  ? c.glucose_mgL
                  : c.glucose_mmolL,
              dev: c.device_type,
              date: c.measuredAtFormatted,
              unit: this.changeGlucoseUnit === "1" ? "(mg/dL)" : "(mmol/L)",
            });
            break;
          case "7":
            temp_array.push({
              value:
                this.changeCholesterolUnit === "1"
                  ? c.cholesterol_mgL
                  : c.cholesterol_mmolL,
              dev: c.device_type,
              date: c.measuredAtFormatted,
              unit: this.changeCholesterolUnit === "1" ? "(mg/dL)" : "(mmol/L)",
            });
            break;
        }
      });
      if (this.deviceType === 3) {
        var max_found = Math.max.apply(
          Math,
          temp_array.map(function (o) {
            return o.value;
          })
        );
        var max_found_second_value = Math.max.apply(
          Math,
          temp_array.map(function (o) {
            return o.secondValue;
          })
        );
        var max_found_third_value = Math.max.apply(
          Math,
          temp_array.map(function (o) {
            return o.thirdValue;
          })
        );
        var second_value_date = this.measuredData.filter((c) => {
          return c.Sys === max_found_second_value;
        });
        var third_value_date = this.measuredData.filter((c) => {
          return c.Pul === max_found_third_value;
        });
        var returned_object = temp_array.filter((c) => c.value === max_found);
        returned_object[0].secondValue = max_found_second_value;
        returned_object[0].thirdValue = max_found_third_value;
        returned_object[0].secondValueDate =
          second_value_date[0].measuredAtFormatted;
        returned_object[0].thirdValueDate =
          third_value_date[0].measuredAtFormatted;
        return returned_object[0];
      } else if (this.deviceType === 2) {
        max_found = Math.max.apply(
          Math,
          temp_array.map(function (o) {
            return o.value;
          })
        );
        max_found_second_value = Math.max.apply(
          Math,
          temp_array.map(function (o) {
            return o.secondValue;
          })
        );
        max_found_third_value = Math.max.apply(
          Math,
          temp_array.map(function (o) {
            return o.thirdValue;
          })
        );
        second_value_date = this.measuredData.filter((c) => {
          return c.Spo2 === max_found_second_value;
        });
        third_value_date = this.measuredData.filter((c) => {
          return c.PI === max_found_third_value;
        });
        returned_object = temp_array.filter((c) => c.value === max_found);
        returned_object[0].secondValue = max_found_second_value;
        returned_object[0].thirdValue = max_found_third_value;
        returned_object[0].secondValueDate =
          second_value_date[0].measuredAtFormatted;
        returned_object[0].thirdValueDate =
          third_value_date[0].measuredAtFormatted;
        return returned_object[0];
      } else {
        max_found = Math.max.apply(
          Math,
          temp_array.map(function (o) {
            return o.value;
          })
        );
        returned_object = temp_array.filter((c) => c.value === max_found);
      }
      return returned_object[0];
    },
  },
  mounted: function () {
    this.mapUnits();
  },
  methods: {
    defaultValues(type) {
      var val = {};
      var values = JSON.parse(this.item.deviceDefaultValuesJson);
      switch (this.item.deviceType) {
        case 1:
          val.min =
            this.units.scaleAndTempUnit === "metric"
              ? values.MinTemperature
              : (values.MinTemperature * 9) / 5 + 32;
          val.max =
            this.units.scaleAndTempUnit === "metric"
              ? values.MaxTemperature
              : (values.MaxTemperature * 9) / 5 + 32;
          break;
        case 2:
          if (type === "Spo2") {
            val.min = values.SpO2;
            val.max = 100;
          } else if (type === "HeartBeat") {
            val.min = values.HeartBeatMin;
            val.max = values.HeartBeatMax;
          } else if (type === "Pi") {
            val.min = values.PiMin;
            val.max = values.PiMax;
          }
          break;
        case 3:
          if (type === "Systolic") {
            val.min = values.DiastolicMin;
            val.max = values.SystolicMax;
          } else if (type === "HeartBeat") {
            val.min = values.HeartBeatMin;
            val.max = values.HeartBeatMax;
          }
          break;
        case 5:
          val.ideal =
            this.units.scaleAndTempUnit === "metric"
              ? this.idealWeight
              : this.idealWeight * 2.20462;
          break;
        case 6:
          val.min =
            this.units.glucoseAndCholesterolUnit !== "mg/dL"
              ? values.BloodSugarLevelMin
              : values.BloodSugarLevelMin * 18;
          val.max =
            this.units.glucoseAndCholesterolUnit !== "mg/dL"
              ? values.BloodSugarLevelMax
              : values.BloodSugarLevelMax * 18;
          break;
        case 7:
          val.min =
            this.units.glucoseAndCholesterolUnit !== "mg/dL"
              ? values.CholesterolLevelMin
              : values.CholesterolLevelMin / 0.02586;
          val.max =
            this.units.glucoseAndCholesterolUnit !== "mg/dL"
              ? values.CholesterolLevelMax
              : values.CholesterolLevelMax / 0.02586;
          break;
      }
      return val;
    },
    mapUnits() {
      if (this.units.scaleAndTempUnit === "metric") {
        this.changeTempUnit = "1";
        this.changeScaleUnit = "1";
      } else {
        this.changeTempUnit = "2";
        this.changeScaleUnit = "2";
      }

      if (this.units.glucoseAndCholesterolUnit === "mg/dL") {
        this.changeGlucoseUnit = "1";
        this.changeCholesterolUnit = "1";
      } else {
        this.changeGlucoseUnit = "2";
        this.changeCholesterolUnit = "2";
      }
    },
    time_ago(date) {
      if (typeof date !== "object") {
        date = new Date(date);
      }

      var seconds = Math.floor((new Date() - date) / 1000);
      var intervalType;

      var interval = Math.floor(seconds / 31536000);
      if (interval >= 1) {
        intervalType = "year";
      } else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
          intervalType = "month";
        } else {
          interval = Math.floor(seconds / 86400);
          if (interval >= 1) {
            intervalType = "day";
          } else {
            interval = Math.floor(seconds / 3600);
            if (interval >= 1) {
              intervalType = "hour";
            } else {
              interval = Math.floor(seconds / 60);
              if (interval >= 1) {
                intervalType = "minute";
              } else {
                interval = seconds;
                intervalType = "second";
              }
            }
          }
        }
      }
      if (interval > 1 || interval === 0) {
        intervalType += "s";
      }
      return interval + " " + intervalType + " ago";
    },

    itemRowBackground: function () {
      return "style-dtable";
    },
    lastMeasuredData(device) {
      switch (device) {
        case 1:
          if (this.lastNormalTemperature !== undefined) {
            if (parseInt(this.changeTempUnit) === 1) {
              return this.lastNormalTemperature + " °C";
            } else return this.lastNormalTemperature + " °F";
          }
          return "";
        case 2:
          return this.lastNormalBPM !== undefined
            ? this.lastNormalSpo2 +
                "SpO2 " +
                this.lastNormalBPM +
                "bpm " +
                " Pi " +
                this.lastNormalPI +
                "%"
            : "";
        case 3:
          return this.lastNormalSystolic !== undefined
            ? this.lastNormalSystolic +
                "/" +
                this.lastNormalDiastolic +
                "mmHg " +
                this.lastNormalPulse +
                "bpm "
            : "";
        case 5:
          if (this.lastNormalWeight !== undefined) {
            if (parseInt(this.changeScaleUnit) === 1) {
              return this.lastNormalWeight + " kg ";
            } else return this.lastNormalWeight + " lbs";
          }
          return "";
        case 6:
          if (this.lastNormalGlucose !== undefined) {
            if (parseInt(this.changeGlucoseUnit) === 1) {
              return this.lastNormalGlucose + " mg/dL";
            } else return this.lastNormalGlucose + " (mmol/L)";
          }
          return "";
        case 7:
          if (this.lastNormalCholesterol !== undefined) {
            if (parseInt(this.changeCholesterolUnit) === 1) {
              return this.lastNormalCholesterol + " mg/dL";
            } else return this.lastNormalCholesterol + " (mmol/L)";
          }
          return "";
      }
    },
  },
};
</script>
<style>
/* .v-card--link:before {
  background: none;
} */
.style-dtable {
  font-size: 16px !important;
  font-weight: bold;
  color: #575ce5 !important;
}

.no-background-hover::before {
  background-color: transparent !important;
}

.v-input--selection-controls {
  margin-left: 60px !important;
  margin-top: 5px !important;
  padding-top: 4px;
}
</style>