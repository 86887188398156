<template>
    <div>
        <v-text-field
            v-model="textValue"
            :disabled="disabled"
            :prepend-inner-icon="inputIcon"
            :label="inputLabel"
            :rules="inputRules"
            :type="type"
            :min="min"
            :max="max"
            outlined
            dense
            hide-details
            filled
            style="border-radius: 12px;"
            class="mb-3"
        />
    </div>
</template>

<script>

export default {
    props: {
        type: String,
		inputValue: String,
        inputIcon: String,
        inputLabel: String,
		disabled: {
            default: false,
            type: Boolean,
        },
		inputRules: Array,
        min: String,
        max: String,
	},
    computed: {
        textValue: {
			get () { return this.inputValue; },
			set (newValue) { this.$emit('update:inputValue', newValue); },
		},
    },
}
</script>
